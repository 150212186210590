.boarding-form-login {
    margin-left:auto;
    margin-right:auto;
    position  : relative;
    max-width: 300px;
    text-align: center;
    margin-top: 20px;
}

.boarding-contact-form {
    width: 75%;
    max-width: 500px;
    background-color:rgba(221, 221, 221, 0.9);
    margin-left:auto;
    margin-right:auto;
    border-radius: 20px;
    padding: 25px;
    border: 2px solid #eee;
    margin-top: 20px;
}
.boarding-login-image-background {
    background-image: linear-gradient(rgba(255,255,255,0.55), rgba(255,255,255,0.55)), url('../Assets/images/agr.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.boarding-login-content{
    display: flex;
    padding: 12px 75px;
    margin-left:auto;
    margin-right:auto;
    border: 1px solid gray;
    border-radius: 50px;
    transition: 0.5s;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 16px;
    align-items: center;
    color: #3d4055;
    background: #fff;
    margin-bottom: 50px;

}

.boarding-send-contact-form {
    display: flex;
    padding: 12px 75px;
    margin-left:auto;
    margin-right:auto;
    border: 1px solid gray;
    border-radius: 50px;
    transition: 0.5s;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 16px;
    align-items: center;
    color: #3d4055;
    background: #fff;
    z-index: 90;
    margin-top: 30px;
}

.boarding-send-contact-form:hover{
    cursor: pointer;
    background-color: rgba(0,108,43,1);
    color: #fff;
}

.boarding-login-google-img {
    margin-left:auto;
    margin-right:auto;
    height: 20px;
    margin: 0 6px 0 0;
}

.boarding-login-content:hover{
    cursor: pointer;
    background-color: honeydew;
}

.boarding-btn-signout{
    width: 100%;
    margin-top: 10px;
    padding: 12px 75px;
    border: 1px solid gray;
    border-radius: 50px;
    transition: 0.5s;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 16px;
    align-items: center;
    color: #000;
    background: #ff9999;
    margin-bottom: 20px;
}
.boarding-btn-signout:hover{
    cursor: pointer;
    color:#fff;
    background-color: rgb(231, 58, 58);
}

.boarding-p-text {
    text-align: center;
    font-weight: bold;
}

.boarding-p-text-error {
    color: rgb(231, 58, 58);
}

.boarding-p-text-good {
    color: rgba(0,108,43,1);
}


.boarding-brand-logo-image {
    padding-top: 10px;
    display: block;
}

.boarding-brand-logo-image {
    width: 100px;
    margin-left:auto;
    margin-right:auto;
}

.boarding-footer {
    background-color: #ddd;

    width: 100%;
    text-align: center;
}
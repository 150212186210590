.form_login {
    left      : 50%;
    top       : 50%;
    position  : absolute;
    transform : translate(-50%, -50%);
    max-width: 300px;
}

.login-image-background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    opacity: 0.5;
    z-index: 0;
}

.login-content{
    display: flex;
    padding: 12px 75px;
    border: 1px solid gray;
    border-radius: 50px;
    transition: 0.5s;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 16px;
    align-items: center;
    color: #3d4055;
    background: #fff;
}

.login-google-img {
  height: 20px;
  margin: 0 6px 0 0;
}

.login-content:hover{
    cursor: pointer;
    background-color: honeydew;
}

.btn-signout{
    width: 100%;
    margin-top: 20px;
    padding: 12px 75px;
    border: 1px solid gray;
    border-radius: 50px;
    transition: 0.5s;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 16px;
    align-items: center;
    color: #000;
    background: #ff9999;
}
.btn-signout:hover{
    cursor: pointer;
    color:#fff;
    background-color: rgb(231, 58, 58);
}
.loader {
    position: fixed;
    right: 0;
    width: 50px;
    height: 50px;
    margin: 1rem;
    bottom: 0;
    z-index: 30000;
    display: flex;
    align-content: center;
    justify-content: center;
    opacity: 0.85;
    transition: all 0.1s ease;
    border-radius: 50%;
    padding: 15px;
    background-color: #003B71;
}

.loaderHidden {
    z-index: -1;
    /* background-color: #fff0; */
    /*opacity: 0;*/
    /* background-color: #fff0; */
    opacity: 0;
    transition: all 0.5s ease;
}

.logo {
    width: 60px;
    flex-basis: auto;
    animation: animate 1.3s linear infinite;
}
@keyframes animate {
    0% {
        transform: rotateY(0deg);
    }
    100% {
        transform: rotateY(360deg);
    }
}

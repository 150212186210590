.home-container {
    position: relative;
}

.App-title {
    font-size: 25px;
    font-weight: bold;
    padding-left: 20px;
    padding-bottom: 10px;
    color: rgb(0, 43, 97);
    position: relative;
    margin-top: 10px;
}

.home-content {
    padding: 20px 40px;
    color: #fff;
}

.background-logo {
    margin: 20px 0px;
    width: auto;
    height: 60px;
    vertical-align: middle;
}

.buttons-container {
    margin: -20px;
    margin-top: 20px;
}

.maps-container {
    margin: -20px;
    margin-top: 20px;
    margin-bottom: -120px;
}

.home-button {
    color: #000;
    background-color: #fff;
    display: inline-block;

    height: 110px;
    width: calc(50vw - 80px);
    min-width: 80px;

    margin: 20px;
    margin-bottom: 15px;
    margin-top: 0px;
    border-radius: 15px;
    box-shadow: 5px 5px #88888860;
    padding: 10px;
    text-align: center;
    vertical-align: middle;

    cursor: pointer;
}

.frame-img {
    height: 90%;
    position: relative;
}

.feature-icon {
    margin-top: 5px;
    height: 60px;
    width: auto;
}

.button-text {
    margin-top: 15px;
    font-size: 12px;
}

.dynamic-grid {
    width: 95%;
    display: grid;
    grid-gap: 10px;
    grid-column-gap: 15px;
    grid-row-gap: 30px;
    grid-auto-rows: 'auto';
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    grid-template-rows: repeat(auto-fill, minmax('auto', 1fr));
    grid-auto-columns: 20px;
    margin: 0 auto;
    justify-items: center;
}
.sidebar {
    width: 250px;
    height: 100%;
    position:fixed;
    z-index: 1;
    top: 100px;
    left: 0;
    background-color: #eee;
    overflow-x: hidden;
    transition: 0.5s;
  }

  .sidebar-hide {
    width: 0px;
    height: 100%;
    position:fixed;
    z-index: 1;
    top: 150px;
    left: 0;
    background-color: #eee;
    overflow-x: hidden;
    transition: 0.5s;
  }
  
  .sidebar a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
  }
  
  .sidebar a:hover {
    color: #f1f1f1;
  }
  
  .sidebar .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }
  
  .openbtn {
    font-size: 20px;
    cursor: pointer;
    background-color: #111;
    color: white;
    padding: 10px 15px;
    border: none;
  }
  
  .openbtn:hover {
    background-color: #444;
  }
  
  #home-container-view {
    transition: margin-left .5s;
    padding: 16px;
    margin-left: 230px;
  }

  #home-container-hide {
    transition: margin-left .5s;
    padding: 16px;
  }
  
  /* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
  @media screen and (max-height: 450px) {
    .sidebar {padding-top: 15px;}
    .sidebar a {font-size: 18px;}
  }

/****

***/

.aside {
  color: #fff;
  padding-left: 20px;
  background-image: linear-gradient(30deg , #E0D4AE, #A38560);
  border-top-right-radius: 80px;
  width: 250px;
  height: 100%;
  position:fixed;
  z-index: 1;
  top: 100px;
  left: 0;
  background-color: #eee;
  overflow-x: hidden;
  transition: 0.5s;
}

.aside-hide {
  width: 0px;
  height: 100%;
  position:fixed;
  border-top-right-radius: 50px;
  z-index: 1;
  top: 150px;
  left: 0;
  background-image: linear-gradient(30deg , #E0D4AE, #A38560);
  overflow-x: hidden;
  transition: 0.5s;
}

aside a {
  cursor: pointer;
  font-size: 14px;
  color: #fff;
  display: block;
  padding: 12px;
  padding-left: 30px;
  text-decoration: none;
  -webkit-tap-highlight-color:transparent;
}

aside a:hover {
  color: #000;
  background: #fff;
  outline: none;
  position: relative;
  background-color: #fff;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

aside a i {
  margin-right: 5px;
}

aside a:hover::after {
  content: "";
  position: absolute;
  background-color: transparent;
  bottom: 100%;
  right: 0;
  height: 35px;
  width: 35px;
  border-bottom-right-radius: 18px;
  box-shadow: 0 20px 0 0 #fff;
}

aside a:hover::before {
  content: "";
  position: absolute;
  background-color: transparent;
  top: 38px;
  right: 0;
  height: 35px;
  width: 35px;
  border-top-right-radius: 18px;
  box-shadow: 0 -20px 0 0 #fff;
}

aside p {
  margin: 0;
  padding: 40px 0;
}

.social {
  height: 0;  
}

.social i:before {
  width: 14px;
  height: 14px;
  font-size: 14px;
  position: fixed;
  color: #fff;
  background: #0077B5;
  padding: 10px;
  border-radius: 50%;
  top:5px;
  right:5px;
}

.aside-selected {
  color: #000;
  background: #fff;
  outline: none;
  position: relative;
  background-color: #fff;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

/*******

*******/

.isolated-panel {
    position: fixed;
    left: 0px;
    top: 50px;
    z-index: 10;
}

.header-content {
    height: 100px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image:
    linear-gradient(to bottom, rgba(216, 179, 141, 0.52), rgba(124, 63, 0, 0.73)),
    url('../Assets/images/agr.jpg');
}
img {
    border: 0;
    vertical-align: middle;
    display: inline-block;
    max-width: 100%
}

[class*=" w-icon-"],[class^=w-icon-] {
    font-family: webflow-icons!important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.w-block {
    display: block
}

.w-inline-block {
    max-width: 100%;
    display: inline-block
}

.w-clearfix:after,.w-clearfix:before {
    content: " ";
    display: table;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end: 2;
    grid-row-end: 2
}

.w-clearfix:after {
    clear: both
}

.w-hidden {
    display: none
}

.w-button {
    display: inline-block;
    padding: 9px 15px;
    background-color: #3898ec;
    color: #fff;
    border: 0;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
    border-radius: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

figure {
    margin: 0 0 10px
}

figcaption {
    margin-top: 5px;
    text-align: center
}

.w-list-unstyled {
    padding-left: 0;
    list-style: none
}

.w-embed:after,.w-embed:before {
    content: " ";
    display: table;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end: 2;
    grid-row-end: 2
}

.w-embed:after {
    clear: both
}

fieldset {
    padding: 0;
    margin: 0;
    border: 0
}

[type=button],[type=reset],button {
    border: 0;
    cursor: pointer;
    -webkit-appearance: button
}

.w-form {
    margin: 0 0 15px
}

.w-form-done {
    display: none;
    padding: 20px;
    text-align: center;
    background-color: #ddd
}

.w-form-fail {
    display: none;
    margin-top: 10px;
    padding: 10px;
    background-color: #ffdede
}

.w-select {
    background-color: #f3f3f3
}

.w-select[multiple] {
    height: auto
}

.w-form-label {
    display: inline-block;
    cursor: pointer;
    font-weight: 400;
    margin-bottom: 0
}



@media screen and (max-width: 991px) {
    .w-hidden-medium {
        display: none!important
    }

   
}

@media screen and (max-width: 767px) {

}

@media screen and (max-width: 479px) {


    .w-hidden-tiny {
        display: none!important
    }

    
}

.w-slider {
    position: relative;
    height: 300px;
    text-align: center;
    background: #ddd;
    clear: both;
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: rgba(0,0,0,0)
}

.w-slider-mask {
    position: relative;
    display: block;
    overflow: hidden;
    z-index: 1;
    left: 0;
    right: 0;
    height: 100%;
    white-space: nowrap
}

.w-slide {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    height: 100%;
    white-space: normal;
    text-align: left
}

.w-slider-nav {
    position: absolute;
    z-index: 2;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    padding-top: 10px;
    height: 40px;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: rgba(0,0,0,0)
}

.w-slider-nav.w-round>div {
    border-radius: 100%
}

.w-slider-nav.w-num>div {
    width: auto;
    height: auto;
    padding: .2em .5em;
    font-size: inherit;
    line-height: inherit
}

.w-slider-nav.w-shadow>div {
    box-shadow: 0 0 3px rgba(51,51,51,.4)
}

.w-slider-nav-invert {
    color: #fff
}

.w-slider-nav-invert>div {
    background-color: rgba(34,34,34,.4)
}

.w-slider-nav-invert>div.w-active {
    background-color: #222
}

.w-slider-aria-label {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.w-slider-force-show {
    display: block!important
}


.w-icon-dropdown-toggle {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto 20px auto auto;
    width: 1em;
    height: 1em
}


@media screen and (max-width: 767px) {
    .w-nav-brand {
        padding-left:10px
    }
}


@media (min-width: 768px) {
    
}



.w-nav {
    position: relative;
    background: #ddd;
    z-index: 1000
}

.w-nav:after,.w-nav:before {
    content: " ";
    display: table;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end: 2;
    grid-row-end: 2
}

.w-nav:after {
    clear: both
}

.w-nav-brand {
    position: relative;
    float: left;
    text-decoration: none;
    color: #333
}

.w-nav-menu {
    position: relative;
    float: right
}

[data-nav-menu-open] {
    display: block!important;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #c8c8c8;
    text-align: center;
    overflow: visible;
    min-width: 200px
}

.w--nav-link-open {
    display: block;
    position: relative
}

.w-nav-overlay {
    position: absolute;
    overflow: hidden;
    display: none;
    top: 100%;
    left: 0;
    right: 0;
    width: 100%
}

.w-nav-overlay [data-nav-menu-open] {
    top: 0
}

.w-nav[data-animation=over-left] .w-nav-overlay {
    width: auto
}

.w-nav[data-animation=over-left] .w-nav-overlay,.w-nav[data-animation=over-left] [data-nav-menu-open] {
    right: auto;
    z-index: 1;
    top: 0
}

.w-nav[data-animation=over-right] .w-nav-overlay {
    width: auto
}

.w-nav[data-animation=over-right] .w-nav-overlay,.w-nav[data-animation=over-right] [data-nav-menu-open] {
    left: auto;
    z-index: 1;
    top: 0
}

.w-nav-button {
    position: relative;
    float: right;
    padding: 18px;
    font-size: 24px;
    display: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: rgba(0,0,0,0);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.w-nav-button:focus {
    outline: 0
}

.w-nav-button.w--open {
    background-color: #c8c8c8;
    color: #fff
}

.w-nav[data-collapse=all] .w-nav-menu {
    display: none
}

.w--nav-dropdown-open,.w--nav-dropdown-toggle-open,.w-nav[data-collapse=all] .w-nav-button {
    display: block
}

.w--nav-dropdown-list-open {
    position: static
}

@media screen and (max-width: 991px) {
    .w-nav[data-collapse=medium] .w-nav-menu {
        display:none
    }

    .w-nav[data-collapse=medium] .w-nav-button {
        display: block
    }
}

@media screen and (max-width: 767px) {
    .w-nav[data-collapse=small] .w-nav-menu {
        display:none
    }

    .w-nav[data-collapse=small] .w-nav-button {
        display: block
    }

    .w-nav-brand {
        padding-left: 10px
    }
}

@media screen and (max-width: 479px) {
    .w-nav[data-collapse=tiny] .w-nav-menu {
        display:none
    }

    .w-nav[data-collapse=tiny] .w-nav-button,.w-tab-link {
        display: block
    }
}

.w-ix-emptyfix:after {
    content: ""
}

@keyframes spin {
    0% {
        transform: rotate(0)
    }

    100% {
        transform: rotate(360deg)
    }
}

.w-dyn-empty {
    padding: 10px;
    background-color: #ddd
}

.w-condition-invisible,.w-dyn-bind-empty,.w-dyn-hide {
    display: none!important
}

.w-layout-grid {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
    grid-row-gap: 16px;
    grid-column-gap: 16px
}


.w-pagination-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center
}

.w-pagination-next,.w-pagination-previous {
    display: block;
    color: #333;
    font-size: 14px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 9px 20px;
    background-color: #fafafa;
    border-width: 1px;
    border-color: #ccc;
    border-top: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-style: solid;
    border-radius: 2px
}

.w-checkbox {
    display: block;
    margin-bottom: 5px;
    padding-left: 20px
}

.w-checkbox::before {
    content: ' ';
    display: table;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1
}

.w-checkbox::after {
    content: ' ';
    display: table;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
    clear: both
}

.w-checkbox-input {
    float: left;
    margin: 4px 0 0 -20px;
    line-height: normal
}

.w-checkbox-input--inputType-custom {
    border-width: 1px;
    border-color: #ccc;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-style: solid;
    width: 12px;
    height: 12px;
    border-radius: 2px
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
    background-color: #3898ec;
    border-color: #3898ec;
    background-image: url(https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg);
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
    box-shadow: 0 0 3px 1px #3898ec
}

@media screen and (max-width: 767px) {

}

@media screen and (max-width: 479px) {
    
}




.paragraph-16px {
    font-size: 16px;
    line-height: 162%;
    font-weight: 400
}

.paragraph-20px {
    font-size: 18px;
    line-height: 140%;
    font-weight: 400
}

.paragraph-20px.features-text {
    font-size: 18px;
    line-height: 156%
}

.paragraph-20px.tracking-accounts-grid-text {
    color: #fff;
    font-size: 18px;
    line-height: 140%
}

.style-guide-content-wrap {
    position: relative;
    display: flex;
    width: 90%;
    max-width: 1170px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 55px;
    padding-bottom: 55px;
    justify-content: space-between;
    align-items: flex-start
}

.features-hero-section {
    overflow: visible;
    padding-bottom: 60px;
    background-color: #fff
}

.text-link {
    transition: .2s;
    color: #05f;
    text-decoration: none
}

.text-link:hover {
    color: #000;
    text-decoration: underline
}

.footer-link {
    margin-bottom: 10px;
    transition: color .2s;
    color: rgba(249,250,251,.5);
    font-size: 20px;
    line-height: 150%;
    text-decoration: none
}

.footer-link:hover {
    color: #fff
}

.footer-wrapper {
    display: flex;
    width: 90%;
    max-width: 1140px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 60px;
    padding-bottom: 0;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #2f39a3
}

.footer-wrapper.fotwraperdug {
    background-color: #f9fafb
}

.footer {
    position: relative;
    overflow: hidden;
    padding-bottom: 40px;
    background-color: #2f39a3;
    color: #f9fafb;
    background-clip: border-box;
    -webkit-text-fill-color: inherit
}

.footer.dugfooter {
    background-color: #f9fafb
}

.white-navbar {
    position: relative;
    z-index: 1000;
    background-color: transparent
}

.main-page-wrapper {
    overflow: hidden
}

.main-page-wrapper.sign-up-page {
    display: flex;
    height: 125vh;
    justify-content: flex-start;
    align-items: stretch
}

.main-page-wrapper._404 {
    height: 120vh
}

.main-page-wrapper.overflow-on {
    overflow: visible
}

.wrapper-1170px {
    width: 90%;
    max-width: 1170px;
    margin-right: auto;
    margin-left: auto
}

.wrapper-1170px.nav-wrapper {
    display: flex;
    padding-top: 35px;
    padding-bottom: 35px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: left
}

.brand-logo-image {
    text-align: left
}

.nav-menu-wrapper {
    display: flex;
    max-width: 340px;
    justify-content: space-between;
    align-items: center
}

.nav-menu-wrapper.white-nav {
    max-width: 500px;
    justify-content: flex-end;
    flex-wrap: wrap
}

.nav-links-left {
    display: flex;
    width: 100%;
    max-width: 500px;
    justify-content: space-between;
    align-items: center;
    grid-column-gap: 40px
}

.nav-button {
    padding: 12px 30px;
    border: 1px solid #3d77f7;
    border-radius: 10px;
    background-color: #3d77f7;
    transition: .2s;
    color: #f9fafb;
    line-height: 162%;
    font-weight: 700
}

.nav-button:hover {
    background-color: #fff;
    color: #3d77f7
}

.nav-button.white-button:hover {
    background-color: #3d77f7
}

.nav-button.menu,.nav-button.white-button.menu {
    display: none
}

.nav-link {
    padding: 0;
    transition: color .2s;
    color: #fff;
    line-height: 150%
}

.nav-link:hover {
    color: #8badfa
}

.nav-link.black {
    color: #1c2c40
}

.nav-link.black:hover {
    color: #3d77f7
}

.style-guide-hero {
    background-color: #f9fafb
}

.wrapper-570px {
    width: 95%;
    max-width: 570px;
    margin-right: auto;
    margin-left: auto
}

.wrapper-570px.style-guide-hero-wrapper {
    padding-top: 40px;
    padding-bottom: 90px
}

.style-guide-hero-heading {
    margin-bottom: 24px;
    font-family: Plusjakartasans,sans-serif;
    color: #1c2c40;
    font-size: 52px;
    line-height: 119%;
    font-weight: 800;
    text-align: center
}

.style-guide-hero-text {
    font-family: Plusjakartasans,sans-serif;
    color: #1c2c40;
    font-size: 20px;
    line-height: 150%;
    text-align: center
}

.style-guide-link-heading {
    font-family: Plusjakartasans,sans-serif;
    color: #1c2c40;
    font-size: 20px;
    line-height: 150%;
    font-weight: 800
}

.style-guide-content-wrapper-right {
    width: 80%;
    max-width: 920px
}

.colors-section {
    width: 90%;
    max-width: 920px
}

.color-shade-card-wrap {
    width: 100%;
    max-width: 920px;
    margin-top: 60px;
    padding-bottom: 30px;
    border-bottom: 1px solid #d2d5d9
}

.colors-heading {
    margin-bottom: 16px;
    font-family: Plusjakartasans,sans-serif;
    color: #1c2c40;
    font-size: 32px;
    line-height: 144%
}

.colors-text {
    margin-bottom: 30px;
    font-family: Plusjakartasans,sans-serif;
    color: #1c2c40;
    line-height: 162%
}

.color-heading {
    margin-bottom: 0
}

.color-shade-box-grid {
    display: -ms-grid;
    display: grid;
    max-width: 920px;
    grid-auto-columns: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.color-shade-box {
    height: 184px;
    max-width: 184px;
    background-color: #6492f9
}

.color-shade-box.primary-color-1 {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: #3d77f7
}

.color-shade-box.primary-color-3 {
    background-color: #8badfa
}

.color-shade-box.primary-color-4 {
    background-color: #b1c9fc
}

.color-shade-box.primary-color-5 {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #d8e4fd
}

.color-shade-box.accent-color-1 {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: #2f39a3
}

.color-shade-box.accent-color-2 {
    background-color: #5961b5
}

.color-shade-box.accent-color-3 {
    background-color: #8288c8
}

.color-shade-box.accent-color-4 {
    background-color: #acb0da
}

.color-shade-box.accent-color-5 {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #d5d7ed
}

.color-shade-box.gray-color-1 {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: #1c2c40
}

.color-shade-box.gray-color-2 {
    background-color: #495666
}

.color-shade-box.gray-color-3 {
    background-color: #77808c
}

.color-shade-box.gray-color-4 {
    background-color: #a4abb3
}

.color-shade-box.gray-color-5 {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #d2d5d9
}

.color-shade-box.white-color-1 {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: #fff
}

.color-shade-box.white-color-2 {
    background-color: #ccc
}

.color-shade-box.white-color-3 {
    background-color: #999
}

.color-shade-box.white-color-4 {
    background-color: #666
}

.color-shade-box.white-color-5 {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #333
}

.color-grid-heading {
    margin-top: 20px;
    margin-bottom: 0;
    padding-right: 10px;
    font-family: Plusjakartasans,sans-serif;
    color: #1c2c40;
    font-size: 20px;
    line-height: 150%;
    font-weight: 800
}

.color-grid-text {
    font-family: Plusjakartasans,sans-serif;
    color: #1c2c40;
    line-height: 162%
}

.typography-section {
    width: 90%;
    max-width: 920px;
    padding-top: 55px;
    padding-bottom: 55px
}

.typography-column-wrap {
    display: flex;
    padding-top: 20px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d2d5d9
}

.typography-column-wrap.headings {
    border-bottom-width: 0
}

.typography-column-left {
    width: 25%;
    max-width: 230px
}

.typography-column-right {
    display: flex;
    width: 75%;
    max-width: 690px;
    justify-content: flex-start;
    align-items: center
}

.typography-column-2-heading,.typography-column-2-text {
    width: 230px
}

.paragraph-14px {
    font-size: 14px;
    line-height: 171%
}

.display-1 {
    font-size: 18px;
    line-height: 156%
}

.display-2 {
    font-family: Plusjakartasans,sans-serif;
    font-size: 16px;
    line-height: 150%;
    font-weight: 400
}

.elements-heading {
    margin-bottom: 55px
}

.buttons-heading {
    margin-bottom: 16px
}

.buttons-text {
    line-height: 162%
}

.elements-buttons-wrapper {
    display: flex;
    margin-bottom: 60px;
    padding-top: 35px;
    padding-bottom: 35px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d2d5d9
}

.small-button {
    width: 200px;
    height: 40px;
    padding: 7px 51px;
    border-radius: 10px;
    background-color: #3d77f7;
    color: #fff;
    line-height: 162%;
    text-align: center
}

.arrow-button {
    display: flex;
    width: 137px;
    height: 26px;
    justify-content: space-between;
    align-items: center;
    color: #3d77f7;
    line-height: 162%;
    text-decoration: none
}

.input-fields-heading {
    margin-bottom: 16px
}

.form {
    margin-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #d2d5d9
}

.text-field {
    height: 50px;
    max-width: 920px;
    margin-bottom: 30px;
    border-radius: 10px
}

.text-field.area {
    height: 206px;
    max-width: 700px;
    padding-left: 20px;
    background-color: #f9fafb
}

.text-field.area::-moz-placeholder {
    color: #778290;
    font-size: 18px;
    line-height: 156%
}

.text-field.area::-ms-input-placeholder {
    color: #778290;
    font-size: 18px;
    line-height: 156%
}

.text-field.area::placeholder {
    color: #778290;
    font-size: 18px;
    line-height: 156%
}

.text-field.contact-form {
    padding-left: 20px;
    background-color: #f9fafb;
    font-size: 18px;
    line-height: 156%
}

.text-field.contact-form::-moz-placeholder {
    color: #778290;
    font-size: 18px;
    line-height: 156%
}

.text-field.contact-form::-ms-input-placeholder {
    color: #778290;
    font-size: 18px;
    line-height: 156%
}

.text-field.contact-form::placeholder {
    color: #778290;
    font-size: 18px;
    line-height: 156%
}

.textarea {
    max-width: 920px;
    min-height: 250px;
    border-radius: 10px
}

.home-wrapper {
    overflow: visible;
    padding-bottom: 0;
    background-image: linear-gradient(0deg, rgba(113,153,32,1) 0%, rgba(0,108,43,1) 100%);
    color: #acb0da
}

.home-hero-wrapper {
    position: relative;
    width: 90%;
    max-width: 970px;
    margin-right: auto;
    margin-left: auto
}

.home-hero-content-wrapper {
    position: relative;
    width: 80%;
    max-width: 750px;
    margin-right: auto;
    margin-left: auto
}

.home-hero-heading {
    width: 100%;
    margin-bottom: 20px;
    color: #fff;
    font-size: 60px;
    text-align: center
}

.home-hero-heading-text {
    margin-bottom: 40px;
    color: #f9fafb;
    font-size: 20px;
    line-height: 140%;
    text-align: center
}

.home-hero-button-contain {
    display: flex;
    max-width: 537px;
    margin: 0 auto 60px;
    justify-content: center
}

.home-hero-button-contain.cta {
    margin-bottom: 0
}

.home-hero-image-container {
    position: relative;
    display: inline-block;
    max-width: 700px;
    margin-right: auto;
    margin-left: auto;
}

/*.home-hero-image {
    position: relative;
    z-index: 10;
    margin-right: auto;
    margin-left: auto;
    border-radius: 10px;
    box-shadow: 0 59px 160px 0 rgba(28,44,64,.08);
}*/
.home-hero-image {
    position: relative;
    z-index: 10;
    margin-right: auto;
    margin-left: auto;
    border-radius: 10px;
}

.logo-section {
    position: relative;
    overflow: hidden;
    padding-top: 10px;
    background-color: #fff
}

.logo-section-wrapper {
    position: relative;
    z-index: 99;
    width: 90%;
    max-width: 1170px;
    margin-right: auto;
    margin-left: auto
}

.logo-section-heading {
    width: 900px;
    margin-right: auto;
    margin-bottom: 70px;
    margin-left: auto;
    color: #1f2451;
    font-weight: 400;
    text-align: center
}

.logo-grid {
    display: -ms-grid;
    display: grid;
    width: 100%;
    max-width: 1170px;
    justify-items: center;
    align-items: center;
    grid-auto-columns: 1fr;
    grid-column-gap: 60px;
    grid-row-gap: 0px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    opacity: .8
}

.logo-grid.logo-grid-1 {
    margin-top: 30px;
    -ms-grid-columns: 0.5fr 1.5fr 1.5fr 1.5fr 1fr;
    grid-template-columns: 0.5fr 1.5fr 1.5fr 1.5fr 1fr
}

.logo-grid.logo-grid-2 {
    margin-top: 40px
}

.logo-grid-wrapper {
    width: 100%;
    max-width: 188px
}

.grid-logo-image {
    max-width: 130%
}

.features-section {
    overflow: hidden;
    padding-top: 80px;
    padding-bottom: 140px
}

.features-wrapper {
    width: 90%;
    max-width: 1170px;
    margin-right: auto;
    margin-left: auto
}

.features-heading {
    max-width: 970px;
    margin-right: auto;
    margin-bottom: 80px;
    margin-left: auto;
    font-size: 40px;
    text-align: center
}

.features-grid {
    position: static;
    overflow: auto;
    grid-auto-flow: row;
    grid-auto-rows: minmax(auto,1fr);
    grid-column-gap: 60px;
    grid-row-gap: 60px;
    grid-template-areas: "Area";
    -ms-grid-columns: 1fr 60px 1fr 60px 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto 60px auto;
    grid-template-rows: auto auto;
    -o-object-fit: fill;
    object-fit: fill
}

.features-grid-wrap {
    display: flex;
    max-width: 350px;
    flex-direction: column;
    align-items: flex-start
}

.features-grid-heading {
    margin-top: 0;
    margin-bottom: 8px
}

.features-grid-icon {
    margin-bottom: 20px
}

.white-button {
    display: flex;
    overflow: visible;
    margin-top: 0;
    padding: 16px 32px;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 7px 7px 15px 0 rgba(0,15,130,.15);
    transition: .2s;
    color: #3d77f7;
    font-size: 18px;
    line-height: 156%;
    font-weight: 700;
    text-align: center
}

.white-button:hover {
    background-color: #2f39a3;
    color: #fff
}

.white-button.cta {
    transition: .2s
}

.white-button.cta:hover {
    background-color: #2f39a3;
    color: #fff
}

/*.home-hero-background-gradient {
    position: absolute;
    left: 0;
    top: 6%;
    right: 0;
    bottom: auto;
    z-index: 1;
    width: 80%;
    height: 62%;
    margin-right: auto;
    margin-left: auto;
    border-radius: 300px;
    background-color: #3d77f7;
    filter: blur(100px)
}*/
.home-hero-background-gradient {
    position: absolute;
    left: 0;
    top: 6%;
    right: 0;
    bottom: auto;
    z-index: 1;
    width: 80%;
    height: 62%;
    margin-right: auto;
    margin-left: auto;
    border-radius: 300px;
    filter: blur(100px)
}

.home-hero-content-contain {
    position: relative;
    z-index: 15;
    margin-top: 30px
}

.tracking-accounts-section {
    position: relative;
    overflow: hidden;
    background-color: #719920;
    color: #719920
}

.tracking-accounts-wrapper {
    display: flex;
    width: 90%;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 30px;
    justify-content: space-between
}

.tracking-accounts-content-wrapper {
    width: 66%;
    max-width: 700px
}

.tracking-accounts-heading {
    margin-bottom: 60px;
    color: #fff
}

.tracking-accounts-grid {
    width: 100%;
    margin-bottom: 140px;
    grid-column-gap: 16px;
    grid-row-gap: 40px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto auto;
    grid-template-rows: auto auto auto auto
}

.tracking-account-grid-wrap {
    display: flex;
    max-width: 500px;
    align-items: flex-start;
}

.tracking-accounts-grid-icon {
    margin-right: 30px;
    padding: 10px;
    border-radius: 10px;
}

.tracking-accounts-image-wrapper {
    overflow: visible;
    width: 71%;
    text-align: center;
}

.tracking-account-card-image {
    position: absolute;
    left: auto;
    top: auto;
    right: 0;
    bottom: 0;
    overflow: visible;
    width: 55%
}

.tracking-account-window-image {
    position: absolute;
    left: auto;
    top: auto;
    right: 0;
    bottom: 0;
    z-index: 222;
    overflow: visible;
    width: 55%
}

.insights-section {
    overflow: hidden
}

.insights-wrapper {
    display: flex;
    width: 90%;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 140px;
    padding-bottom: 140px;
    justify-content: space-between;
    align-items: center
}

.insights-image-wrapper {
    position: relative;
    width: 52%
}

.insights-text-wrapper {
    width: 45%;
    max-width: 470px
}

.insights-image-1 {
    position: absolute;
    left: 0;
    top: -171px;
    right: auto;
    bottom: auto;
    overflow: visible;
    width: 43%;
    border-radius: 10px;
    box-shadow: 10px 22px 100px 0 rgba(28,44,64,.1);
    -o-object-fit: contain;
    object-fit: contain
}

.insights-image-2 {
    position: absolute;
    left: auto;
    top: -200px;
    right: 38px;
    bottom: 0;
    width: 28%;
    border-radius: 10px;
    box-shadow: 10px 22px 100px 0 rgba(28,44,64,.1);
    -o-object-fit: contain;
    object-fit: contain
}

.insights-image-3 {
    position: absolute;
    left: auto;
    top: 45px;
    right: 0;
    bottom: auto;
    width: 44%;
    border-radius: 10px;
    box-shadow: 10px 23px 100px 0 rgba(28,44,64,.1);
    -o-object-fit: contain;
    object-fit: contain
}

.insights-image-4 {
    position: absolute;
    left: auto;
    top: 162px;
    right: 73px;
    bottom: 0;
    width: 16%;
    border-radius: 10px;
    box-shadow: 9px 22px 100px 0 rgba(28,44,64,.1);
    -o-object-fit: contain;
    object-fit: contain
}

.insights-image-5 {
    position: absolute;
    left: 0;
    top: -51px;
    right: auto;
    bottom: 9%;
    width: 48%;
    border-radius: 10px;
    box-shadow: 10px 22px 100px 0 rgba(28,44,64,.1);
    -o-object-fit: contain;
    object-fit: contain
}

.insights-paragraph {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 156%
}

.clients-control-section {
    overflow: hidden;
    background-color: #f9fafb
}

.clients-control-wrapper {
    display: flex;
    width: 90%;
    max-width: 1170px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 140px;
    padding-bottom: 140px;
    justify-content: space-between;
    align-items: center
}

.clients-control-image-wrapper {
    width: 52%;
    max-width: 600px
}

.clients-control-text-wrapper {
    width: 45%;
    max-width: 470px
}

.clients-control-paragraph {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 156%;
    font-weight: 500;
    text-align: left
}

.clients-control-paragraph.textdug {
    position: static;
    overflow: visible;
    width: 500px;
    margin-top: 60px;
    margin-right: auto;
    margin-left: auto;
    transition: opacity .2s;
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    -o-object-fit: fill;
    object-fit: fill
}

.clients-control-heading,.insights-heading {
    margin-bottom: 16px;
    font-size: 40px;
    text-transform: none
}

.clients-control-image-2 {
    position: absolute;
    left: -9%;
    top: 21%;
    right: auto;
    bottom: 0;
    width: 35%;
    border-radius: 10px;
    box-shadow: 10px 22px 100px 0 rgba(28,44,64,.1);
    -o-object-fit: contain;
    object-fit: contain
}

.clients-control-image-3 {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: auto;
    width: 73%;
    border-radius: 10px;
    box-shadow: 10px 22px 100px 0 rgba(28,44,64,.1);
    -o-object-fit: contain;
    object-fit: contain
}

.clients-control-image-1 {
    position: absolute;
    left: 0;
    top: auto;
    right: auto;
    bottom: 0;
    width: 41%;
    border-radius: 10px;
    box-shadow: 10px 22px 100px 0 rgba(28,44,64,.1);
    -o-object-fit: contain;
    object-fit: contain
}

.clients-control-image-4 {
    position: absolute;
    left: auto;
    top: 21%;
    right: 0;
    bottom: auto;
    width: 65%;
    border-radius: 10px;
    box-shadow: 10px 22px 100px 0 rgba(28,44,64,.1);
    -o-object-fit: contain;
    object-fit: contain
}

.clients-control-image-5 {
    position: absolute;
    left: auto;
    top: auto;
    right: 0;
    bottom: 5%;
    width: 51%;
    border-radius: 10px;
    box-shadow: 10px 22px 100px 0 rgba(28,44,64,.1);
    -o-object-fit: contain;
    object-fit: contain
}

.achievement-section {
    overflow: hidden;
    background-color: #719920;
    background-image: url("https://uploads-ssl.webflow.com/631f4c7409a8745d8f9d5782/631f4c7409a874dd559d5861_bg.png");
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    color: #719920
}

.achievement-wrapper {
    width: 90%;
    max-width: 1170px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 120px;
    padding-bottom: 120px
}

.achievement-heading {
    max-width: 630px;
    margin-right: auto;
    margin-bottom: 80px;
    margin-left: auto;
    color: #fff;
    text-align: center
}

.achievement-grid {
    grid-row-gap: 73px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.achievement-grid-cell-wrap {
    display: flex;
    flex-direction: column;
    align-items: center
}

.achievement-grid-icon {
    max-width: 20%
}

.achievement-grid-heading {
    margin-top: 24px;
    margin-bottom: 16px;
    color: #fff;
    font-size: 64px;
    line-height: 112%;
    text-align: center
}

.achievement-grid-text {
    margin-top: 20px;
    color: #fff;
    font-size: 20px;
    line-height: 150%;
    text-align: center
}

.main-cta-section {
    position: relative;
    overflow: hidden;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #006c2b;
    color: #006c2b
}

.footer-top-wrapper {
    position: relative;
    z-index: 200;
    width: 90%;
    max-width: 770px;
    margin-right: auto;
    margin-left: auto
}

.footer-top-heading {
    margin-bottom: 24px;
    color: #fff;
    font-size: 62px;
    text-align: center
}

.footer-top-text {
    width: 100%;
    margin-right: auto;
    margin-bottom: 30px;
    margin-left: auto;
    color: #fff;
    font-size: 20px;
    line-height: 150%;
    text-align: center
}

.footer-grid {
    display: -ms-grid;
    display: grid;
    width: 60%;
    justify-content: space-between;
    justify-items: start;
    grid-auto-columns: 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.footer-grid-cell-wrap {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    cursor: pointer
}

.footer-grid-heading {
    margin-bottom: 16px;
    color: #fff
}

.footer-left-wrapper {
    display: block;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    background-color: #2f39a3;
    text-align: center
}

.footer-left-wrapper.left-footer-dug {
    background-color: #f9fafb
}

.footer-logo-contain {
    margin-bottom: 30px
}

.footer-icon-links-contain {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #2f39a3
}

.footer-text-block {
    color: #77808c;
    line-height: 162%;
    text-align: center
}

.footer-icon-contain {
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
    background-color: #2f39a3;
    cursor: pointer
}

.footer-icon {
    margin-right: 0
}

.powerful-integrations-section {
    overflow: hidden
}

.powerful-integrations-wrapper {
    display: flex;
    width: 90%;
    max-width: 1170px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 140px;
    padding-bottom: 140px;
    justify-content: space-between;
    align-items: center
}

.pi-image-wrapper {
    width: 52%;
    max-width: 600px
}


.slider-section {
    position: relative;
    z-index: 100;
    overflow: hidden;
    background-color: #fff
}

.slider-wrapper {
    width: 90%;
    max-width: 1170px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 40px;
    padding-bottom: 40px
}

.slide-container {
    display: flex;
    padding-top: 0;
    padding-bottom: 0;
    justify-content: space-between;
    align-items: center
}

.slide-left-text {
    width: 45%;
    max-width: 510px
}

.slide-right-image {
    position: relative;
    display: flex;
    width: 44%;
    min-height: 515px;
    justify-content: center
}

.slider-text {
    line-height: 162%;
    font-size: 15px;
}

.mask {
    overflow: hidden;
    background-color: #fff
}

.slider {
    height: auto
}

.slider-image {
    z-index: 2;
    width: 100%;
    height: 100%;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    -o-object-fit: cover;
    object-fit: cover
}

.slide {
    background-color: #fff
}

.slider-text-heading {
    margin-bottom: 40px;
    font-size: 30px;
    font-weight: 500
}

.slider-author-name {
    margin-bottom: 5px
}


.slide-nav {
    display: none
}
.footer-bottom-text-link {
    transition: color .2s;
    color: #006C2B;
    text-decoration: none
}

.footer-bottom-text-link:hover {
    color: #719920
}

.features-hero-wrapper {
    width: 90%;
    max-width: 1170px;
    margin-right: auto;
    margin-left: auto
}

.features-hero-text-contain {
    max-width: 1000px;
    margin-right: auto;
    margin-bottom: 60px;
    margin-left: auto;
    text-align: center
}

.features-hero-heading {
    max-width: 1000px;
    margin-right: auto;
    margin-bottom: 80px;
    margin-left: auto;
    color: #1c2c40;
    font-size: 40px
}

.features-hero-text {
    color: rgba(28,44,64,.8);
    font-size: 20px;
    line-height: 150%
}

.features-hero-grid {
    grid-column-gap: 30px;
    grid-row-gap: 30px
}

.features-hero-grid-cell {
    display: flex;
    height: 100%;
    max-width: 570px;
    padding: 32px;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 28px 28px 120px 0 rgba(28,44,64,.08);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.features-grid-icon-wrap {
    width: 72px;
    height: 72px;
    margin-right: 32px;
    flex: 0 0 auto
}

.features-grid-text-wrap {
    max-width: 400px
}

.features-grid-text {
    font-size: 18px;
    line-height: 156%
}

.features-cta-section {
    overflow: hidden;
    padding-top: 64px;
    padding-bottom: 140px;
    background-color: #fff
}

.features-cta-wrapper {
    display: flex;
    width: 90%;
    max-width: 1170px;
    margin-right: auto;
    margin-left: auto;
    padding: 54px 80px 50px;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    background-color: #001852
}

.features-cta-heading {
    width: 60%;
    max-width: 600px;
    margin-bottom: 0;
    color: #fff
}

.how-section {
    background-image: url("https://uploads-ssl.webflow.com/631f4c7409a8745d8f9d5782/631f4c7409a8744a039d5827_bg-min.png");
    background-position: 0 0;
    background-size: 1440px
}

.how-wrapper {
    width: 90%;
    max-width: 1170px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 120px;
    padding-bottom: 120px
}

.how-heading {
    margin-bottom: 80px;
    color: #fff;
    text-align: center
}

.how-grid {
    position: relative;
    grid-column-gap: 60px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.how-grid-cell-wrap {
    display: flex;
    max-width: 350px;
    flex-direction: column;
    justify-content: center;
    align-items: center
}

.how-grid-cell-wrap.arrow {
    position: absolute;
    left: 24%;
    top: 12%;
    right: auto;
    bottom: auto
}

.how-grid-cell-wrap.arrow-right {
    position: absolute;
    left: auto;
    top: 12%;
    right: 24%;
    bottom: auto
}


.tabs-section {
    overflow: hidden
}

.slider-element {
    position: absolute;
    left: 3%;
    top: 11%;
    right: auto;
    bottom: auto;
    z-index: 20;
    width: 17%
}

.tabs-wrapper {
    width: 90%;
    max-width: 1255px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 60px;
}

.tabs-heading {
    margin-bottom: 16px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 30px;
    font-family: Plusjakartasans,sans-serif;
    color: #1c2c40;
    font-size: 52px;
    line-height: 115%;
    font-weight: 700
}

.tabs-text {
    font-size: 20px;
    line-height: 150%;
    text-align: center
}

.tabs-heading-contain {
    max-width: 820px;
    margin-right: auto;
    margin-bottom: 80px;
    margin-left: auto
}

.tabs-bottom-contain {
    display: flex;
    justify-content: space-between
}

.tabs-bottom-left {
    display: -ms-grid;
    display: grid;
    width: 40%;
    margin-top: 60px;
    margin-bottom: 50px;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto
}

.tabs-bottom-right {
    position: relative;
    width: 59%
}

.tab-card {
    width: 100%;
    max-width: 470px;
    padding-bottom: 35px;
    border-bottom: 1px solid rgba(28,44,64,.2);
    background-color: #3d77f7;
    text-decoration: none;
    cursor: pointer
}

.tab-card-heading-icon-wrap {
    position: relative;
    display: flex;
    padding-top: 30px;
    padding-right: 30px;
    padding-left: 30px;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px
}

.tab-card-bottom-wrap {
    padding-top: 0;
    padding-right: 30px;
    padding-left: 30px
}

.tab-card-icon {
    position: absolute
}

.tab-card-heading {
    margin-bottom: 0;
    color: #fff
}

.tab-card-text {
    max-width: 380px;
    padding-top: 20px;
    color: #fff;
    font-size: 16px;
    line-height: 150%
}

.apply-now-link {
    display: flex;
    margin-top: 30px;
    justify-content: flex-start;
    color: #fff;
    text-decoration: none
}

.tab-text-link {
    line-height: 162%
}

.tabs-image {
    position: absolute;
    left: auto;
    top: 0;
    right: -13%;
    bottom: auto;
    z-index: 10;
    -o-object-fit: fill;
    object-fit: fill
}

.questions-section {
    position: relative;
    z-index: 100
}

.questions-wrapper {
    width: 90%;
    max-width: 670px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 140px;
    padding-bottom: 140px
}

.questions-heading {
    max-width: 530px;
    margin-right: auto;
    margin-left: auto;
    text-align: center
}

.question-card {
    border-bottom: 1px solid rgba(28,44,64,.2);
    cursor: pointer
}

.question-top {
    display: flex;
    padding-top: 24px;
    padding-right: 23px;
    padding-bottom: 24px;
    justify-content: space-between;
    align-items: center
}

.answer-wrap {
    padding-bottom: 0
}

.question-heading {
    margin-right: 2%;
    margin-bottom: 0
}

.answer-text-bottom {
    margin-bottom: 0;
    padding-right: 8%;
    padding-bottom: 20px;
    font-size: 20px;
    line-height: 150%
}

.questions-bottom-wrapper {
    display: flex;
    margin-top: 64px;
    padding-top: 46px;
    padding-bottom: 50px;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    background-color: #2f39a3;
    background-image: url("https://uploads-ssl.webflow.com/631f4c7409a8745d8f9d5782/631f4c7409a87454359d5862_bg2.png");
    background-position: 0 0;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff
}

.questions-bottom-heading {
    margin-bottom: 10px;
    color: #fff
}

.questions-bottom-icon {
    margin-bottom: 24px
}

.slider-color-film {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    border-top-left-radius: 300px;
    border-top-right-radius: 300px;
    background-color: rgba(47,57,163,.2);
    -o-object-fit: fill;
    object-fit: fill
}

.color-film-wrap {
    position: relative;
    width: 100%;
}


.tab-grid-icon-wrap {
    position: relative;
    width: 30px;
    height: 30px
}

.features {
    background-color: #f9fafb
}

.pricing-hero-section {
    padding-top: 60px;
    padding-bottom: 140px;
    background-color: #fff
}

.pricing-hero-wrapper {
    width: 90%;
    max-width: 1170px;
    margin-right: auto;
    margin-left: auto
}

.pricing-hero-heading {
    max-width: 523px;
    margin-right: auto;
    margin-bottom: 32px;
    margin-left: auto;
    text-align: center
}

.pricing-header-grid {
    display: flex;
    max-width: 796px;
    margin-right: auto;
    margin-bottom: 60px;
    margin-left: auto;
    justify-content: center;
    justify-items: stretch;
    align-items: center;
    grid-auto-columns: 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 0px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.header-grid-wrap {
    display: flex;
    margin-right: 0;
    justify-content: center;
    align-items: center
}

.header-grid-text {
    font-size: 20px;
    line-height: 140%
}

.header-geid-icon-wrap {
    width: 30px;
    height: 30px;
    margin-right: 16px
}

.pricing-grid {
    margin-top: 60px;
    grid-column-gap: 30px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.pricing-grid-cell-wrap {
    display: flex;
    padding: 65px 40px 50px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 10px;
    background-color: #001852
}

.pricing-grid-cell-wrap.white {
    height: 100%;
    padding-right: 35px;
    padding-left: 35px;
    align-items: flex-start;
    border: 1px solid #e9e9ee;
    background-color: #fff;
    box-shadow: 28px 28px 120px 0 rgba(28,44,64,.04)
}

.pricing-grid-icon-wrap {
    display: flex;
    width: 100%;
    margin-bottom: 30px;
    justify-content: space-between;
    align-items: center
}

.pricing-grid-icon {
    width: 60px;
    height: 60px
}

.most-popular-wrap {
    border-radius: 5px;
    background-color: #3ebe8a
}

.most-popular-text {
    width: 100.0002616%;
    padding: 2px 10px;
    color: #fff;
    line-height: 162%
}

.pricing-grid-text-wrap {
    display: flex;
    width: 100%;
    margin-bottom: 28px;
    flex-direction: column;
    align-items: flex-start
}

.pricing-grid-heading {
    margin-bottom: 20px;
    color: #fff
}

.pricing-grid-heading.black {
    color: #1c2c40
}

.pricing-grid-text-point-wrap {
    display: flex;
    margin-bottom: 16px;
    align-items: flex-start;
    color: #fff
}

.pricing-grid-text-point-wrap.black {
    color: #1c2c40
}

.pricing-grid-text-point-wrap.down-align {
    margin-top: 8px;
    margin-bottom: 0;
    align-items: flex-end
}

.pricing-grid-text-point-wrap.black-align-down {
    margin-top: 8px;
    margin-bottom: 0;
    flex-wrap: wrap;
    align-items: flex-end;
    color: #1c2c40
}

.pricing-grid-point-heading {
    margin-bottom: 0;
    color: #fff
}

.pricing-grid-point-heading.price-black {
    color: #1c2c40
}

.pricing-grid-point-heading.ecommerce-price {
    color: #1c2c40;
    font-size: 31px
}

.pricing-grid-point-text {
    margin-left: 0;
    font-size: 18px;
    line-height: 156%
}

.pricing-grid-point-text.price {
    font-size: 16px;
    line-height: 162%
}

.pricing-grid-pointer-wrap {
    display: flex;
    margin-bottom: 16px;
    justify-content: flex-start;
    align-items: flex-start
}

.pricing-grid-check-icon {
    margin-right: 10px
}

.pricing-grid-pointer-text {
    color: #fff;
    line-height: 150%
}

.pricing-grid-pointer-text.black {
    color: #000
}

.black-navbar {
    background-color: transparent
}

.pricing-toggle-wrap {
    display: flex;
    justify-content: center;
    align-items: center
}

.toggle-row {
    display: flex;
    padding: 10px 20px 10px 15px;
    align-items: center;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 25px 60px 0 rgba(32,44,108,.1)
}

.toggle-text {
    color: #778290;
    line-height: 162%
}

.toggle-text.dark {
    color: #1c2c40
}

.toggle-contain {
    width: 70px;
    height: 40px;
    margin-right: 20px;
    margin-left: 20px;
    padding: 5px;
    border-radius: 30px;
    background-color: #3d77f7
}

.toggle-dot {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: #fff
}

.hiring-section {
    background-color: #f9fafb
}

.hiring-wrapper {
    width: 90%;
    max-width: 1170px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 150px;
    padding-bottom: 150px
}

.hiring-heading {
    margin-bottom: 0;
    text-align: center
}

.hiring-text {
    font-size: 24px;
    line-height: 142%;
    text-align: center
}

.hiring-heading-contain {
    margin-bottom: 24px
}

.open-positions-wrap {
    margin-top: 80px
}

.open-positions-top {
    display: flex;
    margin-bottom: 30px;
    padding-bottom: 30px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(28,44,64,.1)
}

.open-positions-heading {
    margin-bottom: 0
}

.positions-grid-cell-wrap {
    height: 100%;
    max-width: 370px;
    padding: 30px;
    border: 1px solid #e9e9ee;
    border-radius: 10px;
    background-color: #fff;
    transition: box-shadow .3s;
    color: #1c2c40;
    text-decoration: none
}

.positions-grid-cell-wrap:hover {
    box-shadow: 17px 17px 120px 0 rgba(28,44,64,.1)
}

.positions-grid-cell-bottom-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start
}

.positions-grid-bottom-heading {
    margin-bottom: 8px
}

.positions-grid-text {
    margin-right: 0;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 156%
}

.positions-grid-text.dot {
    margin-right: 5px;
    margin-left: 5px;
    opacity: .4
}

.positions-grid-link {
    display: flex;
    align-items: center;
    text-decoration: none
}

.positions-grid-link-text {
    margin-right: 8px;
    color: #3d77f7;
    font-size: 18px;
    line-height: 156%;
    font-weight: 700
}

.blog-hero-image {
    width: 100%;
    max-height: 690px;
    -o-object-fit: cover;
    object-fit: cover
}

.blog-content-wrapper {
    width: 90%;
    max-width: 1170px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 60px
}

.blog-heading-wrap {
    position: relative;
    display: flex;
    max-width: 755px;
    margin-right: auto;
    margin-bottom: 60px;
    margin-left: auto;
    justify-content: space-between
}

.blog-heading-icons-wrap {
    position: absolute;
    left: -50px;
    top: 0;
    right: auto;
    bottom: 0;
    display: flex;
    margin-right: 50px;
    flex-direction: column;
    justify-content: flex-start
}

.blog-heading-text-wrap {
    max-width: 670px;
    margin-right: auto;
    margin-left: auto
}

.blog-heading-text-wrap.featured {
    position: relative;
    z-index: 999
}

.log-icon-link-image {
    margin-bottom: 19px
}

.about-blog-wrap {
    display: flex;
    max-width: 277px;
    justify-content: flex-start;
    align-items: center
}

.about-blog-wrap.grid {
    width: 100%;
    max-width: 329.203125px;
    justify-content: flex-start
}

.blog-heading {
    margin-bottom: 20px
}

.blog-heading.white {
    margin-bottom: 24px;
    color: #fff
}

.blog-heading.grid {
    max-width: 370px
}

.author-image-contain {
    width: 50px;
    height: 50px;
    margin-right: 18px
}

.about-text-contain {
    width: 80%
}

.about-text-bottom {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
    opacity: .8
}

.author-name {
    margin-bottom: 8px;
    font-weight: 500
}

.author-name.white {
    color: #fff
}

.author-name.template {
    font-weight: 700
}

.calender {
    margin-right: 4px
}

.button {
    font-size: 18px;
    line-height: 156%;
    font-weight: 700
}

.date-text {
    margin-right: 0;
    opacity: .6;
    line-height: 150%
}

.date-text.white {
    flex: 0 0 auto;
    opacity: 1;
    color: #fff
}

.time-text {
    opacity: .6
}

.time-text.white {
    flex: 0 0 auto;
    opacity: 1;
    color: #fff
}

.featured-blog-section-wrapper {
    width: 90%;
    max-width: 1170px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 60px;
    flex-direction: row;
    justify-content: space-between
}

.featured-blog-left {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    max-height: 470px;
    max-width: 670px;
    padding: 0;
    align-items: flex-end;
    border-radius: 10px;
    background-image: url("https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg");
    background-position: 50% 50%;
    background-size: cover;
    text-decoration: none
}

.featured-blog-right {
    position: relative;
    display: flex;
    overflow: hidden;
    max-height: 470px;
    max-width: 470px;
    padding: 0;
    align-items: flex-end;
    border-radius: 10px;
    background-image: url("https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg");
    background-position: 50% 50%;
    background-size: cover;
    text-decoration: none
}

.blog-grid-wrapper {
    width: 90%;
    max-width: 1170px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 80px;
    padding-bottom: 115px
}

.blog-grid-heding {
    margin-bottom: 40px;
    text-align: center
}

.collection-list-wrapper {
    grid-auto-columns: 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 40px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto
}

.collection-item {
    height: 100%;
    grid-auto-columns: 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 40px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto
}

.collection-list {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 40px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto
}

.blog-grid-cell-wrap {
    display: flex;
    height: 100%;
    flex-direction: column;
    border-radius: 10px
}

.author-image {
    margin-right: 30px;
    -o-object-fit: contain;
    object-fit: contain
}

.collection-list-featured-wrapper {
    width: 58%;
    min-height: 470px
}

.collection-list-featured-wrapper-2 {
    width: 40%
}

.blog-grid-hero-image {
    border-radius: 10px
}

.blog-header-wrap {
    display: flex;
    justify-content: space-between
}

.blog-page-heading {
    margin-bottom: 64px;
    text-align: center
}

.blog-grid-link-cells {
    height: 100%;
    color: #1c2c40;
    text-decoration: none
}

.subscribe-cta-section {
    margin-bottom: 120px
}

.subscribe-cta-section.blog-template {
    margin-bottom: 0;
    padding-bottom: 60px
}

.subscribe-wrapper {
    display: flex;
    width: 90%;
    max-width: 1170px;
    margin-right: auto;
    margin-left: auto;
    padding: 64px 80px;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    background-color: #2f39a3
}

.subscribe-wrapper.blog-template {
    max-width: 670px;
    padding: 40px;
    flex-direction: column
}

.subscribe-text-wrap {
    max-width: 450px;
    margin-right: 80px
}

.subscribe-text-wrap.blog-template {
    max-width: none;
    margin-bottom: 32px
}

.subscribe-text {
    color: #fff;
    font-size: 18px;
    line-height: 156%
}

.subscribe-heading {
    color: #fff
}

.subscribe-heading.blog-template {
    margin-bottom: 8px
}

.email-form-wrap {
    width: 100%
}

.subscribe-form-flex {
    display: flex;
    padding: 8px 8px 8px 20px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    border-radius: 10px;
    background-color: #fff
}

.subscribe-form-input-wrapper {
    width: 100%;
    text-align: left
}

.subscribe-form-input {
    margin-bottom: 0;
    padding-left: 0;
    border-style: none;
    border-width: 1px 1px 1px 0;
    border-color: #000;
    font-size: 18px;
    line-height: 156%
}

.subscribe-form-input::-moz-placeholder {
    color: #778290;
    font-size: 18px;
    line-height: 156%
}

.subscribe-form-input::-ms-input-placeholder {
    color: #778290;
    font-size: 18px;
    line-height: 156%
}

.subscribe-form-input::placeholder {
    color: #778290;
    font-size: 18px;
    line-height: 156%
}

.about-author-section {
    padding-bottom: 140px
}

.about-author-wrap {
    display: flex;
    max-width: 635px;
    margin-right: auto;
    margin-left: auto;
    justify-content: flex-start;
    align-items: center
}

.about-author-image-contain {
    margin-right: 30px
}

.image-2 {
    -o-object-fit: contain;
    object-fit: contain
}

.at-text,.designation-text {
    margin-right: 5px;
    font-weight: 500
}

.author-image-icon {
    border-radius: 100%;
    -o-object-fit: contain;
    object-fit: contain
}

.more-blog-section {
    padding-top: 120px;
    padding-bottom: 120px;
    background-color: #f9fafb
}

.more-blog-wrap {
    width: 90%;
    max-width: 1170px;
    margin-right: auto;
    margin-left: auto
}

.collection-list-2 {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}


.positions-grid-text-contain {
    display: flex;
    margin-bottom: 24px;
    flex-direction: row;
    align-items: center
}

.positions-design-collection-list,.positions-marketing-collection-list,.positions-sales-collection-list {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.application-form-heading {
    color: #fff
}

.field-label {
    margin-bottom: 8px;
    color: #fff;
    font-size: 18px;
    line-height: 156%;
    font-weight: 400
}

.field-label.black {
    font-family: Plusjakartasans,sans-serif;
    color: #1c2c40;
    font-weight: 500
}

.text-box {
    height: 50px;
    margin-bottom: 24px;
    border-style: none;
    border-color: #000;
    border-radius: 10px;
    background-color: rgba(61,119,247,.1);
    color: #fff
}

.form-buttons-container {
    display: flex;
    margin-bottom: 24px;
    flex-direction: row;
    flex-wrap: wrap;
    grid-row-gap: 16px
}

.form-attach-button {
    margin-right: 15px;
    padding: 13px 18px;
    border-radius: 10px;
    background-color: #2f39a3;
    line-height: 150%
}

.form-attach-button.last {
    margin-right: 0
}

.careers-temp-form {
    display: flex;
    flex-direction: column
}

.submit-button {
    padding: 16px 96px;
    border-radius: 10px;
    background-color: #3d77f7;
    font-size: 18px;
    line-height: 156%;
    font-weight: 700;
    text-align: center
}

.submit-button.contact-form {
    padding-right: 32px;
    padding-left: 32px;
    transition: background-color .2s;
    font-weight: 700
}

.submit-button.contact-form:hover {
    background-color: #1f2451
}

.submit-button.careers {
    padding-right: 40px;
    padding-left: 40px;
    text-align: center
}

.submit-button.careers:hover {
    background-color: #fff;
    color: #1c2c40
}

.collection-list-5 {
    height: 100%
}

.collection-item-2,.collection-list-6 {
    display: flex;
    height: 100%
}

.collection-item-3 {
    display: flex;
    width: 100%;
    height: 100%
}

.contact-hero-section {
    padding-top: 0;
    padding-bottom: 120px;
    background-color: #f9fafb
}

.contact-hero-wrapper {
    width: 90%;
    max-width: 1170px;
    margin-top: 60px;
    margin-right: auto;
    margin-left: auto
}

.contact-heading-contain {
    max-width: 574px;
    margin-right: auto;
    margin-bottom: 64px;
    margin-left: auto
}

.contact-heading {
    margin-bottom: 16px;
    text-align: center
}

.contact-heading-text {
    font-size: 20px;
    line-height: 140%;
    text-align: center
}

.contact-form-contain {
    width: 71%;
    max-width: 830px;
    min-height: 624px;
    padding: 30px 50px 30px 80px;
    border: 1px solid #e9e9ee;
    border-radius: 10px;
    background-color: #fff
}

.contact-form-name-wrap {
    display: flex;
    justify-content: space-between;
    grid-column-gap: 30px
}

.first-name-contain,.last-name-contain {
    display: flex;
    width: 50%;
    flex-direction: column
}

.contact-form-flex-wrap {
    display: flex;
    justify-content: space-between;
    grid-column-gap: 30px
}

.contact-form-flex-contain {
    position: relative;
    display: flex;
    margin-bottom: 80px;
    justify-content: flex-end
}

.form-button-flex-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-end
}

.contact-form-float {
    position: absolute;
    left: 0;
    top: 30px;
    right: auto;
    bottom: 30px;
    display: flex;
    width: 32%;
    max-width: 370px;
    padding: 40px 30px;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    background-color: #2f39a3;
    box-shadow: 0 25px 60px 0 rgba(32,44,108,.1)
}

.assistance-heading {
    margin-top: 40px;
    margin-bottom: 24px;
    color: #fff;
    text-align: center
}

.email-us-text {
    margin-bottom: 0;
    color: #fff;
    text-align: center
}

.contact-float-text {
    margin-bottom: 21px;
    color: #fff;
    font-size: 20px;
    line-height: 133%
}

.contact-float-paragraph {
    color: #fff;
    font-size: 18px;
    line-height: 156%;
    text-align: center
}

.contact-page-grid {
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.contact-grid-cell {
    display: flex;
    max-width: 570px;
    padding: 40px 30px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 10px 22px 120px 0 rgba(28,44,64,.1)
}

.contact-grid-icon-wrap {
    margin-right: 30px
}

.contact-grid-text-wrap {
    max-width: 400px
}

.contact-grid-icon {
    margin-bottom: 24px
}

.contact-grid-heading {
    margin-top: 0;
    margin-bottom: 8px;
    text-align: center
}

.contact-grid-text {
    font-size: 18px;
    line-height: 156%;
    text-align: center
}

.sign-up-page-left {
    position: relative;
    z-index: 999;
    display: flex;
    width: 50%;
    padding: 37px 0 0;
    flex-direction: column;
    background-color: #fff
}

.sign-up-page-right {
    position: relative;
    z-index: 999;
    overflow: hidden;
    width: 50%;
    height: 100vh;
    background-color: #2f39a3
}

.blue-ellipse._2 {
    left: auto;
    top: 16%;
    right: -5%;
    bottom: auto;
    width: 69px;
    height: 69px
}

.blue-ellipse.tracking {
    left: auto;
    top: auto;
    right: 26%;
    bottom: 0;
    z-index: 1;
    width: 175px;
    height: 171px
}

.pricing-grid-button-wrap {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center
}

.image-gradient {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    padding: 30px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    border-radius: 10px;
    background-image: linear-gradient(180deg,rgba(28,44,64,0),#1c2c40)
}

.cta-elements-wrapper {
    position: relative;
    z-index: 2
}

.cta-element-image-left {
    position: absolute;
    left: auto;
    top: auto;
    right: 0;
    bottom: 0;
    z-index: 1
}

.cta-element-image-right {
    position: absolute;
    left: 0;
    top: auto;
    right: auto;
    bottom: 0;
    z-index: 1
}

.sign-up-form {
    display: flex;
    width: 100%;
    max-width: 470px;
    margin-right: auto;
    margin-left: auto;
    flex-direction: column;
    align-items: flex-start
}

.sign-up-page-logo {
    margin-bottom: 84px
}

.sign-up-form-heading {
    display: block;
    overflow: visible;
    width: 100%;
    margin-bottom: 5px;
    text-align: left;
    -o-object-fit: fill;
    object-fit: fill
}

.sign-up-form-paragraph {
    margin-bottom: 10px;
    line-height: 156%;
    text-align: center
}

.sign-up-form-text {
    margin-bottom: 15px;
    line-height: 156%;
    font-weight: 500;
    text-align: center
}

.sign-up-page-background-gradient {
    position: absolute;
    left: -64%;
    top: 18%;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 550px;
    height: 550px;
    margin-right: auto;
    margin-left: auto;
    border-radius: 300px;
    background-color: #3d77f7;
    filter: blur(450px)
}

.checkbox-text {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 156%;
    font-weight: 400
}

.checkbox-text-link {
    transition: .2s;
    color: #3d77f7;
    text-decoration: none
}

.checkbox-text-link:hover {
    color: #000;
    text-decoration: underline
}

.sign-up-form-text-field {
    display: flex;
    width: 100%;
    height: 60px;
    max-width: 450px;
    margin-bottom: 20px;
    padding-left: 30px;
    border: 1px solid #e9e9ee;
    border-radius: 10px;
    background-color: #f9fafb
}

.sign-up-form-text-field::-moz-placeholder {
    color: #778290;
    font-size: 18px;
    line-height: 156%
}

.sign-up-form-text-field::-ms-input-placeholder {
    color: #778290;
    font-size: 18px;
    line-height: 156%
}

.sign-up-form-text-field::placeholder {
    color: #778290;
    font-size: 18px;
    line-height: 156%
}

.sign-up-form-text-field.confirm-password {
    height: 58px;
    padding-left: 0;
    border-style: none;
    background-color: #f9fafb
}

.sign-up-form-text-field.confirm-password-contain {
    padding-right: 28px;
    padding-left: 30px;
    background-color: #f9fafb
}

.sign-up-form-text-field.password {
    height: 58px;
    padding-left: 0;
    border-style: none;
    background-color: #f9fafb
}

.sign-up-form-block {
    width: 100%;
    margin-top: 45px
}

.form-already-text {
    margin-bottom: 0;
    line-height: 156%;
    font-weight: 500;
    text-align: left
}

.form-heading-contain {
    margin-right: 0;
    margin-bottom: 28px;
    margin-left: 0
}

.licenses-hero {
    background-color: #f9fafb
}

.licenses-content-wrap {
    position: relative;
    display: flex;
    width: 90%;
    max-width: 1170px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 55px;
    padding-bottom: 0;
    justify-content: space-between;
    align-items: flex-start
}

.licenses-links {
    position: -webkit-sticky;
    position: sticky;
    top: 40px;
    display: flex;
    width: 100%;
    max-width: 200px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #000;
    font-size: 16px;
    text-decoration: none
}

.licenses-link-heading {
    font-family: Plusjakartasans,sans-serif;
    color: #1c2c40;
    font-size: 20px;
    line-height: 150%;
    font-weight: 800
}

.licenses-link {
    margin-bottom: 10px;
    justify-content: center;
    font-family: Plusjakartasans,sans-serif;
    color: #1c2c40;
    font-size: 18px;
    line-height: 162%;
    text-decoration: none
}

.licenses-link.w--current,.licenses-link:hover {
    color: #3d77f7
}

.licenses-content-wrapper-right {
    width: 80%;
    max-width: 920px
}

.licenses-hero-text {
    font-family: Plusjakartasans,sans-serif;
    color: #1c2c40;
    font-size: 20px;
    line-height: 140%;
    text-align: center
}

.home-blog-section {
    display: none;
    overflow: hidden;
    padding-top: 120px;
    padding-bottom: 120px;
    background-color: #f9fafb
}

.empty-state {
    border-radius: 10px;
    background-color: #3d77f7;
    color: #fff;
    text-align: center
}

.sign-up-google-text {
    color: #1c2c40
}

.google-icon {
    margin-right: 16px
}

.changelog-hero {
    background-color: #f9fafb
}

.changelog-hero-heading {
    margin-bottom: 24px;
    font-family: Plusjakartasans,sans-serif;
    color: #1c2c40;
    font-size: 52px;
    line-height: 119%;
    font-weight: 800;
    text-align: center
}

.changelog-content-wrap {
    position: relative;
    display: flex;
    width: 90%;
    max-width: 1170px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 55px;
    padding-bottom: 100px;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start
}

.changelog-links {
    position: -webkit-sticky;
    position: sticky;
    display: flex;
    width: 100%;
    max-width: 200px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #000;
    font-size: 16px;
    text-decoration: none
}

.changelog-content-wrapper-right {
    width: 80%;
    max-width: 920px;
    border-bottom: 1px solid #d2d5d9
}

.changelog-link-heading {
    font-family: Plusjakartasans,sans-serif;
    color: #1c2c40;
    font-size: 20px;
    line-height: 150%;
    font-weight: 800
}

.changelog-link {
    margin-bottom: 10px;
    justify-content: center;
    font-family: Plusjakartasans,sans-serif;
    color: #1c2c40;
    font-size: 18px;
    line-height: 162%;
    text-decoration: none
}

.changelog-link:hover {
    color: #3d77f7
}

.version-section-heading {
    margin-bottom: 56px
}

.version-date {
    margin-bottom: 40px
}

.version-list-item {
    margin-bottom: 32px;
    font-size: 20px;
    line-height: 140%
}

.utility-page-wrap {
    width: 90%;
    max-width: 450px;
    margin-top: 60px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    align-items: center
}

.utility-page-content {
    display: flex;
    flex-direction: column;
    text-align: center
}

._404-main-page-wrapper {
    display: flex;
    width: 90%;
    max-width: 752px;
    margin-top: 38px;
    margin-right: auto;
    margin-left: auto;
    flex-direction: column;
    align-items: center
}

._404-page-heading {
    margin-bottom: 48px;
    text-align: center
}

._404-page-text {
    margin-top: 51px;
    margin-bottom: 31px;
    font-size: 20px;
    line-height: 140%;
    text-align: center
}

._404-image {
    margin-right: auto;
    margin-left: auto
}

.utility-page-form {
    display: flex;
    flex-direction: column;
    align-items: center
}

.password-image {
    max-width: 100px;
    margin-bottom: 36px
}

.field-label-2 {
    display: none
}

.sign-up-right-image-1 {
    position: absolute;
    left: auto;
    top: auto;
    right: 0;
    bottom: 0;
    z-index: 20
}

.sign-up-right-image-2,.sign-up-right-image-3 {
    position: absolute;
    left: auto;
    top: auto;
    right: 0;
    bottom: 0;
    z-index: 10
}

.sign-up-right-image-4 {
    position: absolute;
    left: auto;
    top: auto;
    right: 0;
    bottom: 0;
    z-index: 40
}

.home-blog-wrapper {
    width: 90%;
    max-width: 1170px;
    margin-right: auto;
    margin-left: auto
}

.home-blog-header-contain {
    display: flex;
    margin-bottom: 80px;
    justify-content: space-between;
    align-items: center
}

.home-blog-heading {
    margin-right: 2%;
    margin-bottom: 0
}

.home-blog-grid {
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto
}

.home-blog-cms-contain,.home-blog-cms-contain.limit-1,.home-blog-cms-contain.limit-2 {
    height: 100%
}

.home-blog-collection-item-1 {
    position: relative;
    z-index: 1;
    overflow: hidden;
    height: 100%;
    padding: 0;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
    text-decoration: none
}

.home-blog-author-contain {
    position: relative;
    z-index: 10;
    display: flex;
    margin-bottom: 0;
    flex-direction: row;
    align-items: center
}

.home-blog-author-image {
    width: 50px;
    height: 50px;
    margin-right: 16px;
    flex: 0 0 auto;
    border: 2px solid #fff;
    border-radius: 100%
}

.home-blog-author-name {
    color: #fff;
    line-height: 150%;
    text-align: left
}

.home-blog-name {
    position: relative;
    z-index: 10;
    margin-bottom: 16px;
    color: #fff
}

.home-blog-date-time-wrap {
    position: relative;
    z-index: 10;
    display: flex;
    flex-wrap: wrap;
    align-items: center
}

.home-blog-background-gradient {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    display: flex;
    padding: 30px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color: rgba(0,0,0,.4)
}

.home-blog-cms-list-2 {
    display: flex;
    height: 100%;
    grid-column-gap: 30px
}

.checkbox-field {
    display: flex;
    margin-bottom: 0;
    align-items: center
}

.accept-terms-field {
    display: flex;
    margin-bottom: 18px;
    align-items: center
}

.checkbox {
    width: 24px;
    height: 24px;
    margin-top: 0;
    margin-right: 16px;
    flex: 0 0 auto;
    border-width: 1px;
    border-color: #3d77f7;
    border-radius: 4px;
    color: #fff
}

.checkbox.w--redirected-checked {
    background-color: #3d77f7;
    background-image: url("https://uploads-ssl.webflow.com/631f4c7409a8745d8f9d5782/631f4c7409a874004c9d5851_check-small.svg");
    background-position: 50% 50%;
    background-size: auto
}


.image-section-text {
    margin-bottom: 30px
}

.image-grid {
    margin-bottom: 56px;
    padding-bottom: 30px;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    border-bottom: 1px solid #d2d5d9
}

.image-grid-image {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.date-contain {
    display: flex;
    margin-right: 16px;
    align-items: center;
    flex: 0 0 auto
}

.time-contain {
    display: flex;
    align-items: center;
    flex: 0 0 auto
}

.icons-section {
    margin-bottom: 56px
}

.icons-header-div {
    display: flex;
    margin-bottom: 30px;
    justify-content: space-between;
    align-items: center
}

.icons-header-heading {
    margin-bottom: 16px
}

.icons-grid {
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.grid-icon-wrap {
    padding: 35px;
    border: 1px solid #d2d5d9
}

.fonts-section {
    margin-bottom: 56px;
    padding-bottom: 32px;
    border-bottom: 1px solid #d2d5d9
}

.fonts-big-heading {
    margin-bottom: 16px;
    font-size: 200px;
    line-height: 75%;
    font-weight: 800
}

.fonts-text {
    font-size: 20px;
    line-height: 250%
}

.fonts-text-contain {
    max-width: 596px;
    margin-left: 40px
}

.illustrations-section {
    margin-bottom: 150px;
    padding-bottom: 32px;
    border-bottom: 1px solid #d2d5d9
}

.text-heading {
    margin-bottom: 20px
}

.ti-text {
    margin-bottom: 50px;
    line-height: 140%
}

.youtube-video-embed {
    margin-bottom: 50px
}

.home-blog-cms-item-1,.home-blog-cms-list-1,.home-blog-cms-wrap-1 {
    height: 100%
}

.empty-state-text {
    color: #fff
}

.home-blog-cms-item-2,.home-blog-cms-wrap-2,.home-blog-cms-wrap-3 {
    height: 100%
}

.home-blog-cms-list-3 {
    display: flex;
    height: 100%;
    flex-direction: row;
    grid-column-gap: 30px
}

.home-blog-cms-item-3 {
    height: 100%
}

.home-blog-text-contain {
    max-width: 370px;
    flex-direction: column;
    justify-content: space-between
}

.next {
    border-radius: 10px;
    background-color: #3d77f7;
    color: #fff;
    font-size: 16px;
    line-height: 156%
}

.next-button-text {
    margin-right: 10px
}

.previous-text {
    margin-left: 10px
}

.contact-float-text-link {
    transition: color .2s;
    color: #fff;
    font-size: 18px;
    line-height: 156%;
    text-decoration: underline
}

.contact-float-text-link:hover {
    color: #d8e4fd
}

.remember-me-wrap {
    display: flex;
    width: 100%;
    margin-bottom: 18px;
    justify-content: space-between
}

.forgot-password-text-link {
    transition: color .2s;
    color: #1c2c40;
    font-size: 18px;
    line-height: 156%;
    text-decoration: none
}

.forgot-password-text-link:hover {
    color: #3d77f7
}

.company-text {
    font-weight: 500
}

.more-heading {
    margin-bottom: 64px
}

.pricing-grid-points-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start
}

.home-blog-background-image {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: 50% 50%;
    object-position: 50% 50%
}

.footer-social-link {
    margin-right: 20px;
    transition: transform .2s
}

.footer-social-link:hover {
    transform: translate(0,-7px)
}

.categories-wrap {
    width: 90%;
    max-width: 1170px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 120px;
    padding-bottom: 120px
}


.authors-template-wrap {
    display: flex;
    width: 90%;
    max-width: 400px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 120px;
    padding-bottom: 120px;
    justify-content: flex-start;
    align-items: center
}

.author-text-flex {
    display: flex
}

.blog-background-image {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: 50% 50%;
    object-position: 50% 50%
}

.instructions-wrapper {
    position: relative;
    display: flex;
    width: 90%;
    max-width: 1170px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 100px;
    padding-bottom: 100px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start
}

.pricing-ecommerce-cms-list {
    display: -ms-grid;
    display: grid;
    margin-top: 40px;
    grid-auto-columns: 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.blog-image-contain {
    overflow: hidden;
    margin-bottom: 20px;
    border-radius: 10px
}

.success-message {
    border-radius: 10px;
    background-color: #3d77f7;
    color: #fff;
    line-height: 156%;
    font-weight: 500
}

.error-message {
    border-radius: 10px;
    background-color: #ffdede;
    color: #333;
    line-height: 156%;
    font-weight: 500
}

.plan-detail-title {
    margin-bottom: 10px;
    color: #0d0e10;
    font-size: 32px;
    line-height: 48px;
    font-weight: 700
}

.plan-wrapper {
    display: flex;
    width: 90%;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 100px;
    padding-bottom: 100px;
    justify-content: space-between;
    align-items: flex-start;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto
}

.text-containers {
    position: relative;
    display: flex;
    width: 48%;
    max-width: 550px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start
}

.text-containers.product-hero {
    width: 45%;
    max-width: 500px
}

.products-name {
    margin-right: 10px;
    margin-bottom: 8px
}

.product-details-contain {
    margin-top: 20px;
    font-size: 18px
}

.option-list {
    margin-top: 20px
}

.plans-hero-section {
    position: relative;
    background-color: transparent
}

.product-name-wrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap
}

.duration-label {
    margin-bottom: 10px
}

.product-box-wrap {
    width: 100%;
    height: auto;
    padding: 40px;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0 10px 30px 0 rgba(15,31,61,.08)
}

.plan-detail-paragraph {
    margin-bottom: 20px;
    font-size: 18px
}

.duration-buttons {
    margin-right: 20px;
    padding: 10px 20px;
    border: 2px solid rgba(201,250,117,0);
    border-radius: 4px;
    background-color: rgba(63,83,216,.1);
    font-weight: 500
}

.duration-buttons.w--ecommerce-pill-selected {
    border: 2px solid rgba(11,11,11,0);
    background-color: #3f53d8;
    color: #fff
}

.buttons-contain {
    display: -ms-grid;
    display: grid;
    margin-top: 30px;
    align-items: flex-start;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.plan-price {
    color: #0d0e10;
    font-size: 38px;
    line-height: 54px;
    font-weight: 700
}

.about-product-text {
    margin-top: 10px;
    margin-bottom: 0;
    color: #545479;
    font-size: 18px;
    line-height: 156%
}

.button-group {
    margin-bottom: 20px
}

.button-cart-contain {
    display: flex;
    align-items: center;
    grid-column-gap: 30px
}

.cart-button {
    padding-right: 5px;
    padding-left: 5px;
    background-color: transparent;
    color: #fff
}

.cart-button.black,.cart-quantity {
    color: #1c2c40
}

.apple-pay {
    height: 60px;
    border-radius: 10px
}

.cart-quantity-black {
    margin-left: 0;
    color: #1c2c40;
    font-size: 15px
}

.author-temp-wrap {
    width: 90%;
    max-width: 1170px;
    margin-right: auto;
    margin-left: auto
}

.pagination {
    padding-top: 64px
}

.checkout-form {
    padding: 60px 0;
    background-color: #f9fafb
}

.checkout-container {
    width: 90%;
    max-width: 1170px;
    margin-right: auto;
    margin-left: auto
}

.block-heading {
    margin-bottom: 0
}

.pay-with-browser {
    height: 60px;
    border-radius: 10px;
    font-size: 18px
}

.block-header {
    padding-top: 16px;
    padding-bottom: 16px;
    align-items: center
}

.questions-text-link {
    color: #fff
}

.grid-2 {
    grid-template-areas: "Area";
    grid-template-columns: repeat(auto-fit,100%);
    -ms-grid-rows: minmax(382px,7fr) 354px;
    grid-template-rows: minmax(382px,7fr) 354px
}

.image-3 {
    -o-object-fit: contain;
    object-fit: contain
}

.image-4 {
    overflow: auto;
    -o-object-fit: cover;
    object-fit: cover
}

.div-block {
    display: flex
}

.div-block-2 {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    background-image: url("https://uploads-ssl.webflow.com/631f4c7409a8745d8f9d5782/6329be504201ff222a3763b0_DJI_0085%202.jpg");
    background-position: 0 0;
    background-size: cover
}

.image-5 {
    position: static;
    display: block;
    border-radius: 0;
    opacity: 0;
    -o-object-fit: contain;
    object-fit: contain
}

.div-block-5 {
    display: flex;
    max-width: 404px
}

.text-span {
    font-size: 30px
}

.bold-text {
    line-height: 112%
}

.text-block {
    text-align: center
}

@media screen and (min-width: 1280px) {
    .main-page-wrapper._404,.main-page-wrapper.sign-up-page {
        height:110vh
    }

    .home-wrapper {
        background-image: linear-gradient(0deg, rgba(113,153,32,1) 0%, rgba(0,108,43,1) 100%);
    }

    .achievement-section,.tracking-accounts-section {
        background-color: #006c2b
    }



}

@media screen and (min-width: 1440px) {
    .footer {
        background-color:#2f39a3
    }

    .main-page-wrapper.sign-up-page {
        height: 110vh
    }

    .main-page-wrapper._404 {
        height: 100vh
    }

    .nav-button {
        background-color: #2f39a3
    }

    /*.home-wrapper {
        background-image: linear-gradient(180deg,#006C2B 85%,#fff 0)
    }*/
    .home-wrapper {
        background-image: linear-gradient(0deg, rgba(113,153,32,1) 0%, rgba(0,108,43,1) 100%);
    }

    .home-hero-content-wrapper {
        max-width: 1000px
    }

    .home-hero-heading {
        font-size: 70px
    }

    .features-grid-icon {
        margin-bottom: 20px
    }

    .tracking-accounts-grid-icon {
        background-clip: border-box;
        -webkit-text-fill-color: inherit;
        padding: 10px;
        border-radius: 10px;
    }

    .achievement-section,.main-cta-section {
        background-color: #006c2b
    }

    .footer-grid {
        grid-column-gap: 60px
    }

    .blue-ellipse.tracking {
        right: 28%
    }

    .utility-page-wrap {
        margin-top: 100px;
        margin-bottom: auto
    }

    ._404-main-page-wrapper {
        margin-top: 60px
    }

    .blog-image-contain {
        overflow: hidden;
        border-radius: 10px
    }

    .image-5 {
        margin-right: 10px;
        margin-bottom: 10px;
        margin-left: auto
    }
}

@media screen and (min-width: 1920px) {
    .main-page-wrapper.sign-up-page {
        height:100vh
    }

    .white-button {
        border: 1px solid #3d77f7;
        box-shadow: none
    }


    .sign-up-page-left {
        height: 100vh
    }

    .blue-ellipse.tracking {
        right: 30%
    }

    .sign-up-form {
        margin-top: auto;
        margin-bottom: auto
    }
}

@media screen and (max-width: 991px) {
    h1 {
        font-size:50px
    }

    h3 {
        font-size: 28px
    }

    h4 {
        font-size: 22px
    }

    h5 {
        font-size: 18px
    }

    .style-guide-links {
        position: static;
        max-width: 1170px;
        margin-right: auto;
        margin-bottom: 60px;
        margin-left: auto;
        padding-right: 18px;
        padding-left: 18px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center
    }

    .paragraph-20px.features-text {
        font-size: 17px
    }


    .footer-link {
        margin-bottom: 15px
    }

    .footer-wrapper {
        padding-top: 100px;
        padding-bottom: 100px;
        flex-direction: column-reverse;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: flex-start
    }

    .white-navbar {
        position: relative
    }

    .main-page-wrapper.sign-up-page {
        height: auto;
        flex-direction: column
    }

    .wrapper-1170px.nav-wrapper {
        width: 90%;
        padding-top: 10px;
        padding-bottom: 10px
    }

    .nav-menu-wrapper {
        width: 100%;
        max-width: none;
        padding-bottom: 30px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        background-color: #fff
    }

    .nav-menu-wrapper.white-nav {
        background-color: #2f39a3
    }

    .nav-links-left {
        width: 95%;
        max-width: none;
        margin: 30px auto 0;
        flex-direction: column;
        align-items: flex-start
    }

    .nav-button {
        display: none;
        margin-left: 0;
        text-align: center
    }

    .nav-button.white-button {
        display: none;
        width: 30%;
        margin-left: 20px
    }

    .nav-button.white-button.menu {
        display: block;
        margin-left: 0
    }

    .nav-button.menu {
        display: block
    }

    .nav-link {
        margin-right: 0;
        margin-bottom: 30px;
        margin-left: 0
    }

    .wrapper-570px.style-guide-hero-wrapper {
        max-width: 470px;
        padding-bottom: 60px
    }

    .style-guide-hero-heading {
        font-size: 42px
    }

    .style-guide-hero-text {
        font-size: 18px
    }

    .style-guide-link-heading {
        display: none
    }

    .style-guide-content-wrapper-right {
        width: 95%;
        max-width: 1170px;
        margin-right: auto;
        margin-left: auto
    }

    .colors-section {
        width: 100%;
        max-width: 1000px
    }

    .color-heading {
        font-size: 36px;
        text-align: center
    }

    .color-shade-box-grid {
        -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr
    }

    .color-shade-box.primary-color-1 {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
    }

    .color-shade-box.primary-color-5 {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    }

    .color-shade-box.accent-color-1 {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
    }

    .color-shade-box.accent-color-5 {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    }

    .color-shade-box.gray-color-1 {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
    }

    .color-shade-box.gray-color-5 {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    }

    .color-shade-box.white-color-1 {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
    }

    .color-shade-box.white-color-5 {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    }

    .color-grid-heading {
        font-size: 18px
    }

    .typography-section {
        width: 100%
    }

    .typography-heading {
        font-size: 36px;
        text-align: center
    }

    .typography-column-2-heading {
        font-size: 26px
    }

    .elements-heading {
        font-size: 36px;
        text-align: center
    }

    .buttons-heading {
        font-size: 24px;
        text-align: center
    }

    .buttons-text {
        text-align: center
    }

    .elements-buttons-wrapper {
        display: -ms-grid;
        display: grid;
        max-width: 450px;
        margin-right: auto;
        margin-left: auto;
        grid-auto-columns: 1fr;
        grid-column-gap: 30px;
        grid-row-gap: 30px;
        -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        -ms-grid-rows: auto auto;
        grid-template-rows: auto auto
    }

    .small-button {
        text-align: center
    }

    .arrow-button {
        margin-right: auto;
        margin-left: auto
    }

    .input-fields-heading {
        font-size: 24px;
        text-align: center
    }

    .typography-column-1-heading {
        width: 100%;
        font-size: 26px
    }

    .home-wrapper {
        overflow: visible;
        padding-bottom: 40px
    }

    .home-hero-content-wrapper {
        margin-top: 30px
    }

    .logo-section {
        padding-top: 10px
    }

    .logo-grid {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        grid-column-gap: 0px;
        -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr
    }

    .logo-grid-wrapper {
        margin-right: 20px
    }

    .grid-logo-image {
        margin-top: 15px
    }

    .features-section {
        padding-top: 120px;
        padding-bottom: 120px
    }

    .features-grid {
        justify-items: center;
        align-items: start;
        -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr
    }

    .white-button {
        text-align: center
    }

    .tracking-accounts-section {
        padding-bottom: 130px
    }

    .tracking-accounts-wrapper {
        margin-right: auto;
        padding-top: 30px;
        flex-direction: column
    }

    .tracking-accounts-content-wrapper {
        width: 95%
    }

    .tracking-accounts-heading {
        text-align: center
    }

    .tracking-accounts-grid {
        margin-bottom: 30px;
        justify-items: center
    }

    .tracking-accounts-image-wrapper {
        width: 100%;
        text-align: center;
    }

    .tracking-account-card-image {
        position: relative;
        bottom: -48px;
        width: 90%;
        -o-object-fit: fill;
        object-fit: fill
    }

    .tracking-account-window-image {
        width: 90%
    }


    .achievement-grid-heading {
        font-size: 50px
    }

    .main-cta-section {
        padding-top: 100px;
        padding-bottom: 100px
    }

    .footer-grid {
        width: 100%;
        justify-items: start
    }

    .footer-left-wrapper {
        margin-bottom: 30px
    }

    .powerful-integrations-wrapper {
        padding-top: 140px;
        padding-bottom: 100px;
        flex-direction: column
    }

    .pi-image-wrapper {
        width: auto
    }

    .slide-container {
        padding-top: 0;
        flex-direction: column;
        justify-content: flex-end
    }

    .slide-left-text {
        width: 95%;
        margin-right: auto;
        margin-bottom: 50px;
        margin-left: auto
    }

    .slide-right-image {
        text-align: center;
        width: 79%;
        margin-right: auto;
        margin-left: auto;
        justify-content: center
    }

    .features-hero-text {
        font-size: 18px
    }

    .features-hero-grid {
        justify-items: center;
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }

    .features-cta-wrapper {
        flex-direction: column
    }

    .features-cta-heading {
        width: 95%;
        text-align: center
    }

    .how-wrapper {
        width: 95%
    }

    .how-grid {
        grid-column-gap: 13px;
        grid-row-gap: 16px
    }

    .how-grid-cell-wrap.arrow {
        left: 18%
    }

    .how-grid-cell-wrap.arrow-right {
        right: 17%
    }

    .slider-element {
        left: 2%
    }

    .tabs-wrapper {
        padding-top: 60px
    }

    .tabs-heading {
        font-size: 40px
    }

    .tabs-text {
        font-size: 19px
    }

    .tabs-bottom-contain {
        flex-direction: column;
        align-items: center
    }

    .tabs-bottom-left {
        width: 100%;
        margin-bottom: 30px;
        justify-items: center
    }

    .tabs-bottom-right {
        position: relative;
        width: 90%
    }

    .tabs-image {
        position: static;
        margin-top: 50px;
        margin-right: auto;
        margin-left: auto
    }

    .pricing-header-grid {
        grid-column-gap: 19px;
        grid-row-gap: 30px;
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }

    .header-grid-text {
        font-size: 18px
    }

    .pricing-grid {
        justify-items: center;
        grid-column-gap: 16px;
        grid-row-gap: 60px;
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }

    .pricing-grid.annually,.pricing-grid.monthly {
        grid-column-gap: 40px;
        grid-row-gap: 40px;
        -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr
    }

    .pricing-grid-point-text {
        font-size: 16px
    }

    .positions-grid-cell-wrap {
        display: flex;
        padding: 20px;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start
    }

    .positions-grid-heading-wrap {
        width: 100%
    }

    .positions-grid-bottom-heading {
        font-size: 18px;
        text-align: left
    }

    .positions-grid-text {
        margin-right: 0;
        margin-bottom: 0;
        font-size: 16px
    }

    .positions-grid-link {
        margin-top: 5px
    }

    .blog-heading-wrap {
        flex-direction: column;
        align-items: stretch
    }

    .blog-heading-icons-wrap {
        position: static;
        left: -33px;
        flex-direction: row
    }

    .blog-heading-text-wrap {
        margin-right: 0;
        margin-left: 0
    }

    .log-icon-link-image {
        margin-right: 20px
    }


    .featured-blog-left {
        height: 100%;
        min-height: 470px
    }

    .featured-blog-right {
        width: 100%;
        max-width: none;
        min-height: 470px
    }

    .collection-list {
        -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr
    }

    .collection-list-featured-wrapper {
        width: 97%;
        height: 100%;
        margin-bottom: 30px
    }

    .collection-list-featured-wrapper-2 {
        width: 97%
    }

    .blog-header-wrap {
        flex-direction: column;
        align-items: center
    }

    .subscribe-wrapper {
        padding-top: 50px;
        padding-bottom: 50px;
        flex-direction: column
    }

    .subscribe-text-wrap {
        margin-bottom: 30px
    }

    .collection-list-2 {
        grid-row-gap: 30px;
        -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr
    }

    .positions-grid-text-contain {
        width: 100%;
        margin-right: auto;
        flex-direction: row;
        align-items: flex-start
    }

    .collection-item-2,.collection-list-5 {
        width: 100%
    }

    .contact-form-contain {
        width: 100%;
        min-height: auto;
        margin-bottom: 60px;
        padding-left: 50px
    }

    .contact-form {
        display: flex;
        flex-direction: column;
        align-items: center
    }

    .contact-form-flex-contain {
        flex-direction: column-reverse;
        align-items: center
    }

    .form-button-flex-wrap {
        align-items: flex-start
    }

    .contact-form-float {
        position: static;
        width: 100%
    }

    .contact-page-grid {
        justify-items: center;
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }

    .contact-grid-icon-wrap {
        margin-right: 0
    }

    .sign-up-page-left {
        width: 90%;
        margin-right: auto;
        margin-left: auto;
        padding-bottom: 100px
    }

    .sign-up-page-right {
        position: relative;
        width: 100%
    }

    .blue-ellipse.tracking {
        right: 47%
    }

    .sign-up-page-background-gradient {
        left: 0;
        top: -24%;
        right: 0;
        bottom: auto
    }

    .licenses-content-wrap {
        flex-direction: column
    }

    .licenses-links {
        position: static;
        max-width: 1170px;
        margin-right: auto;
        margin-bottom: 60px;
        margin-left: auto;
        padding-right: 18px;
        padding-left: 18px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center
    }

    .licenses-link-heading {
        display: none
    }

    .licenses-content-wrapper-right {
        width: 95%;
        max-width: 1170px;
        margin-right: auto;
        margin-left: auto
    }

    .licenses-hero-text {
        font-size: 18px
    }

    .changelog-hero-heading {
        font-size: 42px
    }

    .changelog-content-wrap {
        flex-direction: column
    }

    .changelog-links {
        position: static;
        max-width: 1170px;
        margin-right: auto;
        margin-bottom: 60px;
        margin-left: auto;
        padding-right: 18px;
        padding-left: 18px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center
    }

    .changelog-content-wrapper-right {
        width: 95%;
        max-width: 1170px;
        margin-right: auto;
        margin-left: auto
    }

    .changelog-link-heading {
        display: none
    }

    .utility-page-wrap {
        margin-top: 100px
    }

    .sign-up-right-image-1 {
        position: relative
    }

    .sign-up-right-image-4 {
        left: auto;
        top: auto;
        right: 0;
        bottom: 0
    }

    .home-blog-grid {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }

    .grid-icon-wrap {
        padding: 20px
    }

    .instructions-wrapper {
        flex-direction: column
    }

    .pricing-ecommerce-cms-list {
        grid-column-gap: 30px;
        grid-row-gap: 30px;
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }

    .pricing-ecommerce-cms {
        max-width: 370px;
        margin-right: auto;
        margin-left: auto
    }

    .contact-page-form {
        width: 95%
    }

    .plan-wrapper {
        padding-top: 80px;
        flex-direction: column;
        align-items: center
    }

    .text-containers {
        width: 100%
    }

    .text-containers.product-hero {
        width: 100%;
        justify-content: flex-start;
        align-items: center
    }

    .product-name-wrapper {
        justify-content: flex-start
    }

    .about-product-contain {
        display: flex;
        flex-direction: column;
        align-items: flex-start
    }

    .about-product-text {
        text-align: left
    }

    .button-cart-contain {
        grid-column-gap: 10px
    }

    .checkout-container {
        flex-direction: column
    }

    .left-checkout {
        width: 100%;
        margin-right: 0
    }

    .right-checkout {
        width: 100%
    }
}

@media screen and (max-width: 767px) {
    h1 {
        font-size:40px
    }

    h2 {
        font-size: 36px
    }

    h3 {
        font-size: 26px
    }

    h4 {
        font-size: 20px
    }

    h5 {
        font-size: 16px
    }

    h6 {
        font-size: 14px
    }

    .style-guide-link {
        display: block;
        grid-auto-columns: 1fr;
        -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        -ms-grid-rows: auto auto;
        grid-template-rows: auto auto
    }

    .footer-wrapper {
        padding-top: 60px;
        padding-bottom: 10px;
        justify-content: space-between;
        align-items: center;
        text-align: center
    }

    .brand-logo.w--current {
        padding-left: 0
    }

    .nav-button.white-button {
        width: 38%;
        margin-left: 14px
    }

    .style-guide-hero-heading {
        font-size: 32px
    }

    .style-guide-hero-text {
        font-size: 17px
    }

    .colors-heading {
        font-size: 28px
    }

    .color-heading {
        font-size: 30px
    }

    .color-shade-box {
        height: 130px;
        max-width: 130px
    }

    .typography-section {
        position: relative
    }

    .typography-heading {
        font-size: 30px
    }

    .typography-column-wrap {
        padding-top: 10px;
        flex-direction: column
    }

    .typography-column-wrap.headings {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        padding-top: 16px;
        flex-direction: column;
        background-color: #d5d7ed
    }

    .typography-column-left {
        text-align: center
    }

    .typography-column-right {
        width: 100%
    }

    .typography-column-2-heading {
        font-size: 20px;
        text-align: center
    }

    .typography-column-2-text {
        text-align: center
    }

    .elements-heading {
        font-size: 30px
    }

    .typography-column-1-heading {
        font-size: 20px
    }

    .home-hero-content-wrapper {
        width: 97%
    }

    .home-hero-button-contain {
        flex-direction: column;
        align-items: stretch
    }

    .home-hero-button-contain.cta {
        max-width: 400px
    }

    .logo-section {
        padding-top: 10px
    }

    .logo-section-heading {
        width: 100%;
        margin-bottom: 10px
    }

    .logo-grid {
        justify-content: center;
        align-content: stretch
    }

    .logo-grid-wrapper {
        margin-top: 40px;
        margin-right: 20px;
        padding-left: 20px
    }

    .features-section {
        padding-top: 100px;
        padding-bottom: 100px
    }

    .features-grid {
        display: block;
        justify-content: stretch;
        align-content: stretch;
        grid-column-gap: 30px;
        grid-row-gap: 60px;
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }

    .features-grid-wrap {
        display: block;
        max-width: 360px;
        margin-bottom: 20px
    }

    .features-grid-icon {
        margin-bottom: 10px
    }

    .white-button {
        width: 100%;
        padding-right: 20px;
        padding-left: 20px;
        text-align: center
    }

    .tracking-accounts-section {
        padding-bottom: 50px
    }

    .tracking-account-card-image {
        bottom: 1px
    }

    .tracking-account-window-image {
        width: 81%
    }

    .insights-wrapper {
        width: 90%;
        padding-top: 100px;
        padding-bottom: 100px
    }

    .clients-control-wrapper {
        width: 90%;
        padding-top: 50px
    }

    .clients-control-paragraph.textdug {
        width: 100%;
        margin-top: 20px
    }

    .clients-control-image-2 {
        left: -3%;
        width: 30%
    }

    .achievement-grid {
        grid-column-gap: 16px;
        grid-row-gap: 40px;
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }

    .footer-top-wrapper {
        z-index: 1100
    }

    .footer-top-text {
        font-size: 18px
    }

    .footer-grid {
        justify-items: start;
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }

    .footer-left-wrapper {
        display: block
    }

    .powerful-integrations-wrapper {
        padding-bottom: 100px
    }

    .pi-image-wrapper {
        width: auto
    }

    .slide-right-image {
        min-height: 350px
    }

    .features-hero-text {
        font-size: 16px
    }

    .features-cta-section {
        padding-bottom: 100px
    }

    .features-cta-heading {
        width: 100%
    }

    .how-heading {
        font-size: 32px
    }

    .how-grid {
        max-width: 350px;
        margin-right: auto;
        margin-left: auto;
        grid-column-gap: 13px;
        grid-row-gap: 30px;
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }

    .how-it-works-grid-text {
        font-size: 18px
    }

    .tabs-heading {
        font-size: 32px
    }

    .tabs-text {
        font-size: 16px
    }

    .tabs-bottom-right {
        width: 98%
    }

    .tab-card-text {
        font-size: 18px
    }

    .tabs-image {
        right: -7%
    }

    .answer-text-bottom {
        font-size: 18px
    }

    .questions-text {
        width: 90%;
        text-align: center
    }

    .pricing-header-grid {
        width: 49%;
        max-width: none;
        flex-direction: column;
        justify-items: center;
        align-items: flex-start;
        grid-column-gap: 30px;
        grid-row-gap: 30px;
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }

    .header-grid-wrap {
        margin-right: auto;
        justify-content: flex-start;
        align-items: center
    }

    .pricing-grid.annually,.pricing-grid.monthly {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }

    .hiring-text {
        font-size: 20px
    }

    .positions-grid-cell-wrap {
        padding: 15px
    }

    .about-blog-wrap {
        max-width: none
    }

    .about-text-bottom {
        flex-direction: row;
        align-items: center
    }

    .featured-blog-left,.featured-blog-right {
        min-height: auto
    }

    .collection-list {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }

    .collection-list-featured-wrapper {
        min-height: auto
    }

    .subscribe-wrapper {
        padding-right: 40px;
        padding-left: 40px
    }

    .subscribe-wrapper.blog-template {
        padding-right: 20px;
        padding-left: 20px
    }

    .subscribe-text-wrap {
        margin-right: 0;
        text-align: center
    }

    .subscribe-form-flex {
        max-width: 400px;
        margin-right: auto;
        margin-left: auto;
        padding: 0;
        flex-direction: column;
        align-items: center;
        grid-row-gap: 20px;
        background-color: transparent
    }

    .subscribe-form-input {
        height: 60px;
        margin-bottom: 0;
        padding-top: 18px;
        padding-bottom: 18px;
        padding-left: 20px;
        border-radius: 10px
    }

    .about-author-section {
        padding-bottom: 80px
    }

    .about-author-wrap {
        width: 90%
    }

    .collection-list-2 {
        grid-row-gap: 40px;
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }

    .positions-design-collection-list,.positions-marketing-collection-list,.positions-sales-collection-list {
        -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr
    }

    .form-buttons-container {
        display: flex;
        flex-direction: column
    }

    .form-attach-button {
        margin-right: 0;
        margin-bottom: 15px;
        text-align: center
    }

    .contact-heading-contain {
        width: 90%;
        max-width: 350px
    }

    .contact-heading-text {
        font-size: 18px
    }

    .contact-form-contain {
        padding-right: 30px;
        padding-left: 30px
    }

    .contact-form-name-wrap {
        flex-direction: column
    }

    .first-name-contain,.last-name-contain {
        width: 100%
    }

    .contact-form-flex-wrap {
        flex-direction: column
    }

    .contact-grid-icon {
        margin-bottom: 10px
    }

    .sign-up-page-left {
        padding-bottom: 57px
    }

    .blue-ellipse.tracking {
        right: 27%
    }

    .image-gradient {
        display: flex
    }

    .sign-up-page-logo {
        padding-left: 0
    }

    .licenses-link {
        display: block;
        grid-auto-columns: 1fr;
        -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        -ms-grid-rows: auto auto;
        grid-template-rows: auto auto
    }

    .licenses-hero-text {
        font-size: 17px
    }

    .changelog-hero-heading {
        font-size: 32px
    }

    .changelog-content-wrap {
        padding-bottom: 100px
    }

    .changelog-link {
        display: block;
        grid-auto-columns: 1fr;
        -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        -ms-grid-rows: auto auto;
        grid-template-rows: auto auto
    }

    .version-list-item {
        font-size: 18px
    }

    .home-blog-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center
    }

    .home-blog-header-contain {
        flex-direction: column
    }

    .home-blog-heading {
        margin-right: 0;
        margin-bottom: 30px;
        text-align: center
    }

    .home-blog-grid {
        max-width: 500px
    }

    .home-blog-cms-list-2 {
        flex-direction: column;
        grid-row-gap: 30px
    }

    .image-grid {
        -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr
    }

    .icons-header-div {
        flex-direction: column;
        align-items: flex-start
    }

    .icons-header-text-wrap {
        margin-bottom: 30px
    }

    .icons-grid {
        grid-row-gap: 0px;
        -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr
    }

    .grid-icon-wrap {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center
    }

    .fonts-big-heading {
        margin-bottom: 30px
    }

    .fonts-text-contain {
        margin-left: 0
    }

    .illustrations-section {
        margin-bottom: 100px
    }

    .home-blog-cms-list-3 {
        flex-direction: column;
        grid-row-gap: 30px
    }

    .instructions-wrapper {
        padding-bottom: 100px
    }

    .brand {
        padding-left: 0
    }

    .plan-wrapper {
        width: 90%;
        padding-top: 90px;
        padding-bottom: 90px
    }

    .product-box-wrap {
        padding: 30px
    }

    .div-block-2 {
        min-height: 400px;
        margin-bottom: 20px
    }

    .div-block-3,.div-block-4 {
        margin-bottom: 20px
    }
}

@media screen and (max-width: 479px) {

    .style-guide-link {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }

    .style-guide-links {
        margin-bottom: 30px
    }

    .paragraph-20px.features-text,.paragraph-20px.tracking-accounts-grid-text {
        font-size: 16px
    }

    .style-guide-content-wrap {
        padding-top: 60px
    }

    .footer-link {
        font-size: 18px
    }

    .footer-wrapper {
        padding-top: 80px;
        padding-bottom: 75px;
        justify-content: flex-start
    }

    .wrapper-1170px.nav-wrapper {
        width: 90%;
        padding-top: 16px;
        padding-bottom: 16px
    }

    .brand-logo.w--current {
        width: 50%;
        margin-right: 2%
    }

    .nav-button.white-button {
        width: 60%;
        margin-left: 8px
    }

    .wrapper-570px.style-guide-hero-wrapper {
        padding-bottom: 40px
    }

    .style-guide-hero-text {
        font-size: 16px
    }

    .color-shade-box {
        height: 92px;
        max-width: 92px
    }

    .color-grid-heading {
        font-size: 16px
    }

    .color-grid-text {
        font-size: 14px
    }

    .typography-column-right {
        width: 100%
    }

    .typography-column-2-heading {
        font-size: 16px
    }

    .elements-buttons-wrapper {
        justify-items: center;
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }

    .small-button {
        width: 100%;
        height: auto
    }

    /*.home-wrapper {
        background-image: linear-gradient(180deg,#006C2B 89%,#fff 0)
    }*/
    .home-wrapper {
        background-image: linear-gradient(0deg, rgba(113,153,32,1) 0%, rgba(0,108,43,1) 100%);
    }

    .home-hero-heading {
        font-size: 35px
    }

    .home-hero-heading-text {
        font-size: 16px
    }

    .logo-grid {
        display: flex;
        justify-content: center;
        grid-auto-columns: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
        -ms-grid-rows: auto;
        grid-template-rows: auto
    }

    .logo-grid-wrapper {
        width: 100%;
        max-width: 220px;
        margin-right: 0;
        padding-left: 0
    }

    .grid-logo-image {
        display: block;
        margin-right: auto;
        margin-left: auto
    }

    .features-heading {
        font-size: 26px
    }

    .white-button {
        flex-wrap: wrap
    }

    .tracking-accounts-section {
        padding-bottom: 15px
    }

    .tracking-accounts-content-wrapper {
        margin-right: auto;
        margin-left: auto
    }

    .tracking-accounts-heading {
        font-size: 26px
    }

    .insights-wrapper {
        width: 90%;
        padding-top: 80px;
        padding-bottom: 80px
    }

    .insights-text-wrapper {
        width: 100%;
        margin-top: 80px
    }

    .insights-paragraph {
        font-size: 16px
    }

    .clients-control-wrapper {
        padding-top: 100px;
        padding-bottom: 80px
    }

    .clients-control-text-wrapper {
        margin-top: 80px
    }

    .clients-control-paragraph {
        font-size: 16px
    }

    .clients-control-paragraph.textdug {
        margin-top: 60px;
        font-weight: 400
    }

    .clients-control-heading,.insights-heading {
        font-size: 26px
    }

    .achievement-wrapper {
        padding-top: 80px;
        padding-bottom: 80px
    }

    .achievement-heading {
        font-size: 30px
    }

    .main-cta-section {
        padding-top: 80px;
        padding-bottom: 80px
    }

    .footer-top-heading {
        font-size: 27px
    }

    .footer-top-text {
        font-size: 16px
    }

    .footer-text-block {
        width: 90%;
        margin-right: auto;
        margin-left: auto
    }

    .powerful-integrations-wrapper {
        padding-top: 100px;
        padding-bottom: 80px
    }

    .slide-container {
        padding-top: 0
    }

    .slide-left-text {
        width: 98%
    }

    .slide-right-image {
        width: 100%
    }

    .slider-text-heading {
        font-size: 20px
    }

    .features-hero-heading {
        font-size: 30px;
        margin-top: 0;
        margin-bottom: 30px;
        font-family: Plusjakartasans,sans-serif;
        color: #1c2c40;
        line-height: 115%;
        font-weight: 700
    }

    .features-hero-text {
        font-size: 16px
    }

    .features-hero-grid-cell {
        padding: 24px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box
    }

    .features-grid-text-wrap {
        width: 100%;
        margin-top: 20px
    }

    .features-grid-text {
        font-size: 16px
    }

    .features-cta-wrapper {
        padding-right: 30px;
        padding-left: 30px
    }

    .how-wrapper {
        padding-top: 100px;
        padding-bottom: 100px
    }

    .how-heading {
        font-size: 30px
    }

    .how-it-works-grid-text {
        font-size: 16px
    }

    .tabs-heading {
        font-size: 30px
    }

    .tabs-text {
        font-size: 18px
    }

    .tab-card-bottom-wrap,.tab-card-heading-icon-wrap {
        padding-right: 15px;
        padding-left: 15px
    }

    .tab-card-text {
        font-size: 16px
    }

    .questions-wrapper {
        padding-top: 100px;
        padding-bottom: 100px
    }

    .questions-heading {
        font-size: 30px
    }

    .question-heading {
        margin-right: 5%
    }

    .answer-text-bottom {
        font-size: 16px
    }

    .questions-bottom-heading {
        text-align: center
    }

    .questions-text {
        width: 90%;
        text-align: center
    }

    .pricing-hero-wrapper {
        width: 90%
    }

    .pricing-header-grid {
        width: 82%;
        align-items: flex-start
    }

    .header-grid-wrap {
        margin-right: auto;
        align-items: center
    }

    .header-grid-text {
        font-size: 16px
    }

    .header-geid-icon-wrap {
        margin-right: 10px
    }

    .pricing-grid-cell-wrap,.pricing-grid-cell-wrap.white {
        padding-right: 20px;
        padding-left: 20px
    }

    .pricing-grid-icon-wrap {
        flex-direction: column;
        align-items: flex-start;
        grid-row-gap: 10px
    }

    .most-popular-wrap {
        margin-top: 10px
    }

    .toggle-row {
        padding-right: 5px;
        padding-left: 5px
    }

    .toggle-text {
        font-size: 15px;
        text-align: center
    }

    .toggle-text.dark {
        text-align: center
    }

    .toggle-contain {
        margin-right: 10px;
        margin-left: 10px
    }

    .hiring-text {
        font-size: 18px
    }

    .positions-grid-cell-wrap {
        padding: 15px
    }

    .blog-content-wrapper {
        padding-top: 30px
    }

    .about-blog-wrap {
        align-items: flex-start
    }

    .blog-heading.white {
        font-size: 18px
    }

    .about-text-bottom {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        grid-row-gap: 4px
    }

    .date-text.white,.time-text.white {
        font-size: 14px
    }

    .featured-blog-left,.featured-blog-right {
        height: 300px
    }

    .author-image {
        margin-right: 15px
    }

    .collection-list-featured-wrapper {
        min-height: auto
    }

    .blog-page-heading {
        font-size: 26px
    }

    .subscribe-wrapper {
        padding-right: 10px;
        padding-left: 10px
    }

    .subscribe-wrapper.blog-template {
        padding: 20px 10px
    }

    .subscribe-text-wrap {
        width: 100%;
        max-width: none;
        margin-right: auto;
        margin-left: auto
    }

    .subscribe-form-input-wrapper {
        -ms-grid-row-align: stretch;
        align-self: stretch;
        flex: 1
    }

    .subscribe-form-input {
        width: 100%
    }

    .about-author-section {
        padding-bottom: 40px
    }

    .about-author-wrap {
        flex-direction: column;
        align-items: flex-start;
        grid-row-gap: 16px
    }

    .more-blog-section {
        padding-top: 60px;
        padding-bottom: 60px
    }

    .collection-list-2,.positions-design-collection-list,.positions-marketing-collection-list,.positions-sales-collection-list {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }


    .field-label {
        font-size: 16px
    }

    .form-buttons-container {
        display: flex;
        flex-direction: column;
        align-items: stretch
    }

    .form-attach-button {
        margin-right: 0;
        margin-bottom: 10px;
        text-align: center
    }

    .submit-button {
        padding: 13px 5px;
        text-align: center
    }

    .contact-heading {
        font-size: 29px
    }

    .contact-heading-text {
        font-size: 16px
    }

    .contact-form-contain {
        display: flex;
        padding-right: 10px;
        padding-left: 10px;
        flex-direction: column;
        justify-content: center;
        align-items: stretch
    }

    .contact-form {
        margin-bottom: 0
    }

    .contact-form-name-wrap {
        grid-row-gap: 16px
    }

    .contact-form-flex-wrap {
        margin-top: 16px;
        margin-bottom: 16px;
        grid-row-gap: 16px
    }

    .form-button-flex-wrap {
        align-items: center
    }

    .contact-float-paragraph {
        font-size: 16px
    }

    .contact-grid-cell {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center
    }

    .contact-grid-text {
        font-size: 16px
    }

    .sign-up-page-left {
        padding-bottom: 37px
    }

    .blue-ellipse.tracking {
        right: 43%;
        width: 50px;
        height: 50px
    }

    .image-gradient {
        padding-right: 20px;
        padding-left: 20px
    }

    .sign-up-form-heading {
        font-size: 22px
    }

    .sign-up-form-paragraph {
        font-size: 16px
    }

    .checkbox-text,.sign-up-form-text {
        font-size: 14px
    }

    .sign-up-form-block {
        margin-top: 45px
    }

    .licenses-content-wrap {
        padding-top: 60px
    }

    .licenses-links {
        margin-bottom: 30px;
        padding-right: 0;
        padding-left: 0
    }

    .licenses-link {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }

    .licenses-hero-text {
        font-size: 16px
    }

    .changelog-content-wrap {
        padding-top: 40px;
        padding-bottom: 50px
    }

    .changelog-links {
        margin-bottom: 30px
    }

    .changelog-link {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }

    .version-list-item {
        font-size: 16px
    }

    ._404-page-text {
        font-size: 18px
    }

    .home-blog-background-gradient {
        padding: 15px
    }

    .date-contain {
        margin-bottom: 2px
    }

    .illustrations-section {
        margin-bottom: 100px
    }

    .remember-me-wrap {
        flex-direction: column
    }

    .forgot-password-text-link {
        margin-top: 6px
    }

    .authors-template-wrap {
        flex-direction: column;
        align-items: flex-start;
        grid-row-gap: 16px
    }

    .author-text-flex {
        flex-direction: row;
        flex-wrap: wrap
    }

    .instructions-wrapper {
        padding-top: 10px;
        padding-bottom: 50px
    }

    .brand {
        width: 50%
    }

    .plan-detail-title {
        font-size: 30px;
        line-height: 40px
    }

    .product-box-wrap {
        padding-right: 20px;
        padding-left: 20px
    }

    .plan-detail-paragraph {
        font-size: 16px
    }

    .buttons-contain {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }

    .plan-price {
        font-size: 30px
    }

    .about-product-text {
        font-size: 18px;
        line-height: 150%
    }

    .button-cart-contain {
        grid-column-gap: 0px
    }

    .order-item {
        flex-direction: column
    }

    .order-item-div {
        margin-left: 0
    }

}
.account-body {
    position: absolute;
    top: 120px;
    left: 0px;
    right: 0px;
    bottom: 40px;
    overflow-y: auto;
    z-index:0;
    padding-left: 5%;
    padding-right: 5%;
}

.account-data-text {
    width: 95%;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    border-top: 2px solid #ddd;
    border-right: 2px solid #ddd;
    border-left: 2px solid #ddd;
    color: black;
    padding-bottom: 1px;
}

.account-data-text-end {
    border-bottom: 2px solid #ddd;
}

.account-data-radius-top  {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.account-data-radius-bottom  {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.p-data-text {
    padding: 5px 10px 5px 10px;
}

.img-profile {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 15px;
    margin-bottom: 15px;
    max-width: 250px;
}